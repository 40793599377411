import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { VariantService } from '../../services/variant.service';
import { AuthService } from '../../services/auth.service';
import { Reference } from '../../models/reference';
import { ConfirmationDialogComponent } from '../../popups/confirmation-dialog/confirmation-dialog.component';
import { Unpublished } from '../../models/unpublished';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AlertsService } from '../../services/alerts.service';

@Component({
  selector: 'adpkd-reference-detail',
  templateUrl: './reference-detail.component.html',
  styleUrls: ['./reference-detail.component.scss']
})
export class ReferenceDetailComponent implements OnInit {
  displayedColumns = ['Remove', 'ColumnName', 'ColumnValue','ColumnOrder'];
  dataSource: MatTableDataSource<Unpublished> = new MatTableDataSource;
  reference: Reference;
  errorMessage: string;
  id: number;
  mode: string;
  ModeText: string = "";
  private sort: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.sort = this.sort;
  }

  constructor(private dialogRef: MatDialogRef<ReferenceDetailComponent>,
              private service: VariantService,
              @Inject(MAT_DIALOG_DATA) data,
              private dialog: MatDialog,
              private authService: AuthService,
              private alerts: AlertsService) {

    this.mode = data.mode;

    if (this.mode == 'edit') {
      this.id = data.id;
      this.ModeText = "Update";
    }

    if (this.mode == 'add') {
      this.ModeText = "Add";
    }

    this.dataSource = new MatTableDataSource<Unpublished>([]);
  }

  ngOnInit(): void {

    if (this.id) {
      this.service.getReferenceByID(this.id).subscribe({
        next: reference => {
          this.reference = reference;

          if (this.reference.UnpublishedReference != null) { this.dataSource.data = this.reference.UnpublishedReference; }
        },
        error: err => this.errorMessage = err
      });
    }
    else {
      this.reference = new Reference();
      this.reference.ReferenceID = -1;
      this.reference.UnpublishedReference = new Array<Unpublished>();
    }
  }

  saveReference(): void {

    if (this.mode == 'edit') {
      this.service.updateReference(this.reference).subscribe(() => {
        this.dialogRef.close(this.reference);
      },
        (error) => {
          this.alerts.ShowError(error);
          this.dialogRef.close();
        }
      );
    }

    if (this.mode == 'add') {

      this.service.addReference(this.reference).subscribe(() => {
        this.dialogRef.close(this.reference);
      },
        (error) => {
          this.alerts.ShowError(error);
          this.dialogRef.close();
        }
      );
    }
  }

  deleteReference(): void {

    if (this.mode == 'edit') {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent,
        { data: { title: 'Delete Reference', message: 'Are you sure you wish to delete this reference?' }, disableClose: true, maxWidth: '95%', maxHeight: '95%' });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.service.deleteReference(this.reference).subscribe(() => {
                  this.dialogRef.close("Reference Deleted");
          },
            (error) => {
              this.alerts.ShowError(error); 
              this.dialogRef.close();
            });
        }
      });      
    }
  }

  resetUnpublished(unpublished: boolean) {

    if (unpublished) {
      this.reference.URL = null;
    }
    else {
      this.reference.UnpublishedReference = new Array<Unpublished>();
      this.dataSource.data = this.reference.UnpublishedReference;
    }
  }

  addColumn() {
    this.reference.UnpublishedReference.push(new Unpublished(this.reference.UnpublishedReference.length + 1));

    if (this.reference.UnpublishedReference != null) { this.dataSource.data = this.reference.UnpublishedReference; }
  }

  removeColumn(column: Unpublished) {

    const index = this.reference.UnpublishedReference.indexOf(column, 0);
    if (index > -1) {
      this.reference.UnpublishedReference.splice(index, 1);

      if (this.reference.UnpublishedReference != null) { this.dataSource.data = this.reference.UnpublishedReference; }
    }
  }

  sortUnpublished(column: Unpublished, direction: string) {

    if (direction == 'up') {
      let replace = this.reference.UnpublishedReference.find(col => col.ColumnOrder == column.ColumnOrder - 1);

      replace.ColumnOrder += 1;
      column.ColumnOrder -= 1;
    }
    else {
      let replace = this.reference.UnpublishedReference.find(col => col.ColumnOrder == column.ColumnOrder + 1);

      replace.ColumnOrder -= 1;
      column.ColumnOrder += 1;
    }

    if (this.reference.UnpublishedReference != null) { this.dataSource.data = this.reference.UnpublishedReference; }
  }

  validColumns(): boolean {

    return !this.reference.UnpublishedReference.some(col => col.ColumnName == null || col.ColumnName?.length == 0 || col.ColumnValue == null || col.ColumnValue?.length == 0);
  }

  validURL(): boolean {
    var expression = /(((http|ftp|https):\/{2})+(([0-9a-z_-]+\.)+(aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mn|mn|mo|mp|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|nom|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ra|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw|arpa)(:[0-9]+)?((\/([~0-9a-zA-Z\#\+\%@\.\/_-]+))?(\?[0-9a-zA-Z\+\%@\/&\[\];=_-]+)?)?))\b/gi;
    var regex = new RegExp(expression);

    if (this.reference.URL == null || this.reference.URL.length == 0 || this.reference.Unpublished) {
      return true;
    }

    return regex.test(this.reference.URL);
  }
}

