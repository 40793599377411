import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Gene } from '@app/models/gene';
import { VariantService } from '@app/services/variant.service';
import { Classification } from '@app/models/classification';
import { Filter } from '@app/models/filter';
import { VariantType } from '@app/models/variantType';
import { ClinicalSignificance } from '@app/models/clinicalSignificance';
import { Region } from '@app/models/region';
import { Dictionary } from '@app/models/dictionary';
import { AuthService } from '@app/services/auth.service';
import { PredictedPenetrance } from '@app/models/predictedPenetrance';
import { Reference } from '@app/models/reference';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {ResearchSignificance} from '@app/models/researchSignificance';

@Component({
  selector: 'adpkd-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Output() filterChanged: EventEmitter<Filter> = new EventEmitter<Filter>();
  @Input() variantsLoaded: boolean;

  appliedFilters: string[] = ['Gene'];
  genes: Gene[];
  selectedGene = 1;
  classifications: Classification[];
  selectedClassification = 1;
  variantTypes: VariantType[];
  selectedVariantType = -1;
  clinicalSignificances: ClinicalSignificance[];
  selectedClinicalSignificance = -1;
  researchSignificances: ResearchSignificance[];
  selectedResearchSignificance = -1;
  predictedPenetrances: PredictedPenetrance[];
  selectedPredictedPenetrance = -1;
  regions: Region[];
  statuses: string[] = ['- All -', 'Uploaded', 'Added', 'Updated'];
  status = '- All -';
  selectedReference = -1;
  references: Reference[];
  filteredReferences: Reference[];
  searchPhrase: string;
  searchCodon: string;
  errorMessage: string;
  dictionaryId: number;
  dictionaries: Dictionary[];
  panelOpenState: boolean;
  value: string;
  codon: string;

  exon15: number;
  filteredRegions: Region[];
  selectedRegion = -1;
  hideColumnsForOldData = false;

  /** control for the MatSelect filter keyword */
  public refFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  constructor(public authService: AuthService, private service: VariantService ) { }

  ngOnInit() {
    this.service.getReferences().subscribe({
      next: references => {
        const ref = new Reference;
        ref.ReferenceID = -1;
        ref.Title = ' - All - ';
        ref.Description = ' - All - ';

        this.references = references;
        this.references.unshift(ref);
        this.filteredReferences = this.references;

        // listen for search field value changes
        this.refFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterReferences();
          });
      },
      error: err => this.errorMessage = err
    });

    this.service.getGenes().subscribe({
      next: genes => {
        this.genes = genes;
        this.genes.unshift(new Gene(-1, ' - All - '));
      },
      error: err => this.errorMessage = err
    });

    this.service.getClassifications().subscribe({
      next: classifications => {
        this.classifications = classifications;
      },
      error: err => this.errorMessage = err
    });

    this.service.getVariantTypes().subscribe({
      next: variantTypes => {
        this.variantTypes = variantTypes;
        this.variantTypes.unshift(new VariantType(-3, ' - Any Nontruncating - '));
        this.variantTypes.unshift(new VariantType(-2, ' - Any Truncating - '));
        this.variantTypes.unshift(new VariantType(-1, ' - All - '));
      },
      error: err => this.errorMessage = err
    });

    this.service.getClinicalSignificances().subscribe({
      next: clinicalSignificances => {
        this.clinicalSignificances = clinicalSignificances;
        this.clinicalSignificances.unshift(new ClinicalSignificance(-2, ' - Any Pathogenic - '));
        this.clinicalSignificances.unshift(new ClinicalSignificance(-1, ' - All - '));
      },
      error: err => this.errorMessage = err
    });

    this.service.getResearchSignificances().subscribe({
      next: researchSignificances => {
        this.researchSignificances = researchSignificances;
        this.researchSignificances.unshift(new ResearchSignificance(-2, ' - Any Pathogenic - '));
        this.researchSignificances.unshift(new ResearchSignificance(-1, ' - All - '));
      },
      error: err => this.errorMessage = err
    });

    this.service.getPredictedPenetrances().subscribe({
      next: predictedPenetrances => {
        this.predictedPenetrances = predictedPenetrances;
        this.predictedPenetrances.unshift(new PredictedPenetrance(-1, ' - All - '));
      },
      error: err => this.errorMessage = err
    });

    this.service.getRegions().subscribe({
      next: regions => {
        this.regions = regions;
        this.regions.unshift(new Region(-1, ' - All - ', ' - All - '));

        this.filteredRegions = this.regions;

        this.exon15 = this.regions.find(region => region.Value === 'Exon 15').RegionID;
      },
      error: err => this.errorMessage = err
    });

    this.service.Dictionary().subscribe(dictionary => {
      this.dictionaryId = dictionary?.DictionaryID;

      if (dictionary) {
        this.hideColumnsForOldData = dictionary.Version === 3.1;
      }
    });

    this.service.Dictionaries().subscribe(data => {
      this.dictionaries = data;
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  changeStatusSelection() {
    if (this.status === '- All -') {

      if (this.appliedFilters.indexOf('Record Status') !== -1) {
        this.appliedFilters.splice(this.appliedFilters.indexOf('Record Status'), 1);
      }
    } else {

      if (this.appliedFilters.indexOf('Record Status') === -1) {
        this.appliedFilters.push('Record Status');
      }
    }

    this.EmitFilterChange();
  }

  protected filterReferences() {
    if (!this.references) {
      return;
    }
    // get the search keyword
    let search = this.refFilterCtrl.value;
    if (!search) {
      this.filteredReferences = this.references;
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredReferences = this.references.filter(ref => ref.Title.toLowerCase().indexOf(search) > -1);
  }

  changeGeneSelection() {

    if (this.selectedGene === -1) {

      if (this.appliedFilters.indexOf('Gene') !== -1) {
        this.appliedFilters.splice(this.appliedFilters.indexOf('Gene'), 1);
      }
    } else {

      if (this.appliedFilters.indexOf('Gene') === -1) {
        this.appliedFilters.push('Gene');
      }
    }


    if (this.selectedGene === 2) {
      this.filteredRegions = this.regions.filter(region => region.RegionID <= this.exon15);

      if (this.selectedRegion > this.exon15) {
        this.selectedRegion = -1;
        this.changeRegionSelection();
      }
    }
    else {
      this.filteredRegions = this.regions;
    }

    this.EmitFilterChange();
  }

  changeClassificationSelection() {
    this.EmitFilterChange();
  }

  changeVariantTypeSelection() {

    if (this.selectedVariantType === -1) {

      if (this.appliedFilters.indexOf('Variant Type') !== -1) {
        this.appliedFilters.splice(this.appliedFilters.indexOf('Variant Type'), 1);
      }
    } else {

      if (this.appliedFilters.indexOf('Variant Type') === -1) {
        this.appliedFilters.push('Variant Type');
      }
    }

    this.EmitFilterChange();
  }

  changeClinicalSignificanceSelection() {

    if (this.selectedClinicalSignificance === -1) {

      if (this.appliedFilters.indexOf('Clinical Significance') !== -1) {
        this.appliedFilters.splice(this.appliedFilters.indexOf('Clinical Significance'), 1);
      }
    } else {

      if (this.appliedFilters.indexOf('Clinical Significance') === -1) {
        this.appliedFilters.push('Clinical Significance');
      }
    }

    this.EmitFilterChange();
  }

  changeResearchSignificanceSelection() {

    if (this.selectedResearchSignificance === -1) {

      if (this.appliedFilters.indexOf('Research Significance') !== -1) {
        this.appliedFilters.splice(this.appliedFilters.indexOf('Research Significance'), 1);
      }
    } else {

      if (this.appliedFilters.indexOf('Research Significance') === -1) {
        this.appliedFilters.push('Research Significance');
      }
    }

    this.EmitFilterChange();
  }

  changePredictedPenetranceSelection() {

    if (this.selectedPredictedPenetrance === -1) {

      if (this.appliedFilters.indexOf('Predicted Penetrance') !== -1) {
        this.appliedFilters.splice(this.appliedFilters.indexOf('Predicted Penetrance'), 1);
      }
    } else {

      if (this.appliedFilters.indexOf('Predicted Penetrance') === -1) {
        this.appliedFilters.push('Predicted Penetrance');
      }
    }

    this.EmitFilterChange();
  }

  changeRegionSelection() {

    if (this.selectedRegion === -1) {
      if (this.appliedFilters.indexOf('Exon/Intron') !== -1) {
        this.appliedFilters.splice(this.appliedFilters.indexOf('Exon/Intron'), 1);
      }
    } else {

      if (this.appliedFilters.indexOf('Exon/Intron') === -1) {
        this.appliedFilters.push('Exon/Intron');
      }
    }

    this.EmitFilterChange();
  }

  changeReferenceSelection() {

    if (this.selectedReference === -1) {
      if (this.appliedFilters.indexOf('Reference') !== -1) {
        this.appliedFilters.splice(this.appliedFilters.indexOf('Reference'), 1);
      }
    } else {

      if (this.appliedFilters.indexOf('Reference') === -1) {
        this.appliedFilters.push('Reference');
      }
    }

    this.EmitFilterChange();
  }

  applySearch(searchValue: string) {
    searchValue = searchValue.trim();
    searchValue = searchValue.toLowerCase();
    this.searchPhrase = searchValue;

    this.EmitFilterChange();
  }

  applyCodonSearch(searchValue: string) {
    searchValue = searchValue.trim();

    if (isNaN(Number(searchValue)) || searchValue.length === 0) {
      searchValue = '';
      this.codon = '';
    }

    this.searchCodon = searchValue;

    this.EmitFilterChange();
  }

  remove(filter: string): void {

    this.appliedFilters.splice(this.appliedFilters.indexOf(filter), 1);

    switch (filter) {
      case 'Gene': {
        this.selectedGene = -1;
        this.filteredRegions = this.regions;
        break;
      }
      case 'Classification': {
        this.selectedClassification = -1;
        break;
      }
      case 'Variant Type': {
        this.selectedVariantType = -1;
        break;
      }
      case 'Clinical Significance': {
        this.selectedClinicalSignificance = -1;
        break;
      }
      case 'Research Significance': {
        this.selectedResearchSignificance = -1;
        break;
      }
      case 'Predicted Penetrance': {
        this.selectedPredictedPenetrance = -1;
        break;
      }
      case 'Exon/Intron': {
        this.selectedRegion = -1;
        break;
      }
      case 'Record Status': {
        this.status = '- All -';
        break;
      }
      case 'Reference': {
        this.selectedReference = -1;
        break;
      }
    }

    this.EmitFilterChange();
  }

  private EmitFilterChange() {

    if (!this.showStatusBox()) {
      this.status = '- All -';
      if (this.appliedFilters.indexOf('Record Status') !== -1) {
        this.appliedFilters.splice(this.appliedFilters.indexOf('Record Status'), 1);
      }
    }

    this.filterChanged.emit(
      new Filter(this.selectedGene,
        this.selectedClassification,
        this.selectedVariantType,
        this.selectedClinicalSignificance,
        this.selectedResearchSignificance,
        this.selectedPredictedPenetrance,
        this.selectedRegion,
        this.searchPhrase,
        this.searchCodon,
        this.status,
        this.selectedReference));
  }

  dictionaryChanged() {

    this.service.changeDictionary(this.dictionaries.find(dictionary => dictionary.DictionaryID === this.dictionaryId));

    if (!this.showStatusBox()) {
      this.status = '- All -';
      if (this.appliedFilters.indexOf('Record Status') !== -1) {
        this.appliedFilters.splice(this.appliedFilters.indexOf('Record Status'), 1);
      }
    }
  }

  showStatusBox() {

    return this.authService.authenticated && this.service.IsWorkingDictionaryActive();
  }
}
