import { DictionaryPublish } from './dictionaryPublish';

export class Dictionary {
  DictionaryID: number;
  Version: number;
  Description: string;
  IsActive: boolean;
  VariantCount: number;
  PublishDate: Date;
  Publishes: DictionaryPublish[];
}
