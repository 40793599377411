import { Component, OnInit, ViewChild } from '@angular/core';
import { VariantService } from '../services/variant.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { Dictionary } from '../models/dictionary';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DictionaryDetailComponent } from './dictionary-detail/dictionary-detail.component';
import { DictionaryPublishComponent } from './dictionary-publish/dictionary-publish.component';
import { AlertsService } from '../services/alerts.service';

@Component({
  selector: 'adpkd-dictionaries',
  templateUrl: './dictionaries.component.html',
  styleUrls: ['./dictionaries.component.scss']
})
export class DictionariesComponent implements OnInit {
  displayedColumns = ['Row', 'Description', 'Status', 'Version', 'PublishDate', 'VariantCount'];
  dataSource: MatTableDataSource<Dictionary> = new MatTableDataSource;
  workingId: number;
  errorMessage: string;
  isLoading: boolean = true;
  search: string;
  haveWorking: boolean = false;
  canRollback: boolean = false;

  constructor(private service: VariantService, private dialog: MatDialog, private alerts: AlertsService, public authService: AuthService) { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {

    this.service.Dictionaries().subscribe(data => {
      this.isLoading = true;

      if (data.some(dictionary => dictionary.IsActive == false && dictionary.Publishes.length == 0)) {
        this.workingId = data.find(dictionary => dictionary.IsActive == false && dictionary.Publishes.length == 0).DictionaryID;
        this.haveWorking = true;
      }
      else {
        this.haveWorking = false;
      }

      if (data.length > 1) {
        this.canRollback = true;
      }
      else {
        this.canRollback = false;
      }

      this.dataSource.sort = this.sort;
      this.dataSource.data = data;

      this.isLoading = false;
    });

    this.dataSource.filterPredicate = (data: Dictionary, filter: string) => {
      return data.Description.toLowerCase().indexOf(filter) !== -1;
    };
  }

  applySearch(searchValue: string) {
    searchValue = searchValue.trim();
    searchValue = searchValue.toLowerCase();
    this.search = searchValue;

    this.dataSource.filter = this.search;
  }

  editDictionary(dictionary: Dictionary) {
    let dialogRef = this.dialog.open(DictionaryDetailComponent, { data: { mode: 'edit', id: dictionary.DictionaryID }, disableClose: true, width: '105%', height: '95%' });
 
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dictionary Details dialog was closed', result);

      if (result) {
        if (result == "Dictionary Deleted") {
          this.alerts.ShowNotify(result);
        }
        else {
          let dictionary: Dictionary = result as Dictionary;

          this.alerts.ShowNotify("Dictionary Updated: " + dictionary.Version);
        }
      }
    });
  }

  addDictionary() {
    let dialogRef = this.dialog.open(DictionaryDetailComponent, { data: { mode: 'add' }, disableClose: true, width: '105%', height: '95%' });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Add Dictionary dialog was closed', result);

      if (result) {
        let dictionary: Dictionary = result as Dictionary;

        this.alerts.ShowSuccess("Dictionary Added: " + dictionary.Version);
      }
    });
  }

  publishDictionary() {
    let dialogRef = this.dialog.open(DictionaryPublishComponent, { data: { id: this.workingId }, disableClose: true, width: '105%', height: '95%' });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Publish Dictionary dialog was closed', result);

      if (result) {
        let dictionary: Dictionary = result as Dictionary;

        this.alerts.ShowSuccess("Dictionary Published: " + dictionary.Version);
      }
    });
  }

  rollbackDictionary() {
    this.service.rollbackDictionary().subscribe(() => {
      this.service.loadDictionaries();

      this.alerts.ShowNotify("Dictionary Rolled Back: " + this.service.PublishedDictionary().Version);
    });
  }
}
