import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { VariantService } from '../../services/variant.service';
import { AppConfigService } from '../../services/app-config.service';


@Component({
  selector: 'adpkd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent  {
  title = 'Variant Database';
  environment: string;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(public authService: AuthService, private service: VariantService) {}

  ngOnInit() {

    this.environment = AppConfigService.settings.env;
  } 

  async signIn(): Promise<void> {

    await this.authService.signIn();
  }

  signOut(): void {

    this.authService.signOut();
    this.service.changeToActiveDictionary();
  }
}
