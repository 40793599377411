import { Component, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { Variant } from '../../models/variant';
import { VariantService } from '../../services/variant.service';
import { Filter } from '../../models/filter';
import { VariantDetailsComponent } from '../variant-details/variant-details.component';
import { AuthService } from '../../services/auth.service';
import { AlertsService } from '../../services/alerts.service';

@Component({
  selector: 'adpkd-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements AfterViewInit {
  displayedColumns = ['Row', 'RegionDisplay', 'Codon', 'cDNADesignation', 'AminoAcidDesignation', 'VariantTypeName', 'ClinicalSignificanceName',
    'PredictedPenetranceName', 'Pedigrees', 'gnomADAlleleCount', 'gnomADAlleleNumber'];

  allColumns = ['Row', 'GeneName', 'RegionDisplay', 'Codon', 'cDNADesignation', 'AminoAcidDesignation', 'VariantTypeName', 'ClinicalSignificanceName',
    'ResearchSignificanceName', 'PredictedPenetranceName', 'Pedigrees', 'gnomADAlleleCount', 'gnomADAlleleNumber'];
  allColumnsWithoutgnomAD = ['Row', 'GeneName', 'RegionDisplay', 'Codon', 'cDNADesignation', 'AminoAcidDesignation', 'VariantTypeName', 'ClinicalSignificanceName',
    'Pedigrees'];
  lessColumns = ['Row', 'RegionDisplay', 'Codon', 'cDNADesignation', 'AminoAcidDesignation', 'VariantTypeName', 'ClinicalSignificanceName',
    'ResearchSignificanceName', 'PredictedPenetranceName', 'Pedigrees', 'gnomADAlleleCount', 'gnomADAlleleNumber'];
  lessColumnsWithoutgnomAD = ['Row', 'RegionDisplay', 'Codon', 'cDNADesignation', 'AminoAcidDesignation', 'VariantTypeName', 'ClinicalSignificanceName',
    'Pedigrees'];

  dataSource: MatTableDataSource<Variant> = new MatTableDataSource;
  errorMessage: string;
  isLoading = true;
  filteredVariants: Variant[];
  currentFilter: Filter = new Filter(1, 1, -1, -1, -1, -1, -1, '', '', '- All -', -1);
  variants: Variant[];
  canAddVariants: boolean;
  variantsLoaded = false;
  pedigreeCount: number;
  hideColumnsForOldData = false;

  constructor(public service: VariantService, private dialog: MatDialog, public authService: AuthService, private alerts: AlertsService) {
    this.canAddVariants = service.CanAddEditVariants();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.service.Dictionary().subscribe(
      dictionary => {

        if (dictionary) {
          if (dictionary.Version === 3.1) {
            this.hideColumnsForOldData = true;
          } else {
            this.hideColumnsForOldData = false;
          }
        }

        this.canAddVariants = this.service.CanAddEditVariants();
        this.loadVariants();
      });
  }

  public loadVariants() {

    this.dataSource.data = [];
    this.isLoading = true;

    this.service.getVariants().subscribe({
      next: variants => {
        this.isLoading = false;
        this.variants = variants;

        this.filterChanged(this.currentFilter);
      },
      error: err => {
        this.errorMessage = err;
        this.isLoading = false;
      }
    });

    this.dataSource.filterPredicate = (data: Variant, filter: string) => {
        return data.cDNADesignation.toLowerCase().indexOf(filter) !== -1 || data.AminoAcidDesignation.toLowerCase().indexOf(filter) !== -1;
    };
  }

  filterChanged(filter: Filter) {
    this.currentFilter = filter;

    if (!this.authService.authenticated || !this.service.IsWorkingDictionaryActive()) {
      filter.status = '- All -';
    }

    this.filteredVariants = this.variants;

    if (filter.gene !== -1) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.GeneID === filter.gene);

      if (this.hideColumnsForOldData) {
        this.displayedColumns = this.lessColumnsWithoutgnomAD;
      }
      else {
        this.displayedColumns = this.lessColumns;
      }
    }
    else {
      if (this.hideColumnsForOldData) {
        this.displayedColumns = this.allColumnsWithoutgnomAD;
      }
      else {
        this.displayedColumns = this.allColumns;
      }
    }

    if (filter.status !== '- All -') {

      let status;

      switch (filter.status) {
        case 'Added': {
          status = 1;
          break;
        }
        case 'Updated': {
          status = 2;
          break;
        }
        case 'Uploaded': {
          status = 3;
          break;
        }

      }
      this.filteredVariants = this.filteredVariants.filter(variant => variant.Updated === status);
    }

    if (filter.classification !== -1) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.ClassificationID === filter.classification);
    }

    if (filter.variantType !== -1) {

      if (filter.variantType === -2) { // Any Truncating
        this.filteredVariants = this.filteredVariants.filter(variant => variant.VariantTypeName.indexOf('Truncating:') === 0);
      }
      else if (filter.variantType === -3) { // Any Non Truncating
        this.filteredVariants = this.filteredVariants.filter(variant => variant.VariantTypeName.indexOf('Nontruncating:') === 0);
      }
      else {
        this.filteredVariants = this.filteredVariants.filter(variant => variant.VariantTypeID === filter.variantType);
      }
    }

    if (filter.clinicalSignificance !== -1) {

      if (filter.clinicalSignificance === -2) { // Any Pathogenic
        this.filteredVariants = this.filteredVariants.filter(variant => variant.ClinicalSignificanceName.indexOf('Pathogenic') >= 0);
      }
      else {
        this.filteredVariants = this.filteredVariants.filter(variant => variant.ClinicalSignificanceID === filter.clinicalSignificance);
      }
    }

    if (filter.researchSignificance !== -1) {

      if (filter.researchSignificance === -2) { // Any Pathogenic
        this.filteredVariants = this.filteredVariants.filter(variant => variant.ResearchSignificanceName.indexOf('Pathogenic') >= 0);
      }
      else {
        this.filteredVariants = this.filteredVariants.filter(variant => variant.ResearchSignificanceID === filter.researchSignificance);
      }
    }

    if (filter.predictedPenetrance !== -1) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.PredictedPenetranceID === filter.predictedPenetrance);
    }

    if (filter.region !== -1) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.RegionStartID <= filter.region &&
        variant.RegionEndID >= filter.region);
    }

    if (filter.reference !== -1) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.ReferenceList != null &&
        variant.ReferenceList.some(ref => ref === filter.reference));
    }

    if (filter.searchCodon !== '' && !isNaN(Number(filter.searchCodon))) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.Codon === +filter.searchCodon);
    }

    this.dataSource.filter = filter.searchPhrase;

    if (this.paginator) { this.paginator.firstPage(); }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.data = this.filteredVariants;

    this.service.getPedigreeCount(filter).subscribe(
      value => {
        this.pedigreeCount = value;
      }
    );

    this.variantsLoaded = true;
  }

  amount(item) {
    return item.PedigreeCount;
  }

  sum(prev, next) {
    return prev + next;
  }

  editVariant(id: number) {
    const dialogRef = this.dialog.open(VariantDetailsComponent, { data: {mode: 'edit', id, old: this.hideColumnsForOldData },
      disableClose: true, width: '105%', height: '95%', maxHeight: '95%' }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result === 'Variant Deleted') {
          // this.service.loadDictionaries();
          this.loadVariants();
          this.alerts.ShowNotify(result);
        }
        else {
          const variant: Variant = result as Variant;

          this.alerts.ShowNotify('Variant Updated: ' + variant.cDNADesignation);
        }

        this.loadVariants();
      }
    });
  }

  addVariant() {
    const dialogRef = this.dialog.open(VariantDetailsComponent, { data: { mode: 'add' }, disableClose: true, width: '105%',
      height: '95%', maxHeight: '95%' }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const variant: Variant = result as Variant;

        this.alerts.ShowSuccess('Variant Added: ' + variant.cDNADesignation);

        this.loadVariants();
        // this.service.loadDictionaries();
      }
    });
  }

  resetSort() {
    this.sort.sort({ id: null, start: 'asc', disableClear: false });
  }

  showInfoBox() {

    if (!this.variants) { return false; }

    return this.authService.authenticated && this.service.IsWorkingDictionaryActive() && this.filteredVariants.length >= 5;
  }
}
