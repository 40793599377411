import { Component, OnInit, Inject } from '@angular/core';
import { Variant } from '../../models/variant';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { VariantService } from '../../services/variant.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'adpkd-select-variant',
  templateUrl: './select-variant.component.html',
  styleUrls: ['./select-variant.component.scss']
})
export class SelectVariantComponent implements OnInit {
  variants: Variant[];
  filteredVariants: Variant[];
  errorMessage: string;
  mode: string;
  id: number;
  variant: Variant;

  /** control for the MatSelect filter keyword */
  public refFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<SelectVariantComponent>, private service: VariantService, @Inject(MAT_DIALOG_DATA) data) {
    this.mode = data.mode;

    if (this.mode == 'update') {
      this.id = data.id;
    }
  }

  ngOnInit(): void {

    this.service.getVariantsByWorkingDictionary().subscribe({
      next: variants => {
        this.variants = variants

        this.variantChanged();
        this.setFilteredArray();

        // listen for search field value changes
        this.refFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterVariants();
          });
      },
      error: err => this.errorMessage = err
    });

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  variantChanged() {
    this.variant = this.variants.find(variant => variant.VariantID == this.id);
  }

  setFilteredArray() {

    this.filteredVariants = new Array<Variant>();

    if (this.variant != null) {
      this.filteredVariants.push(this.variant);
    }
  }

  protected filterVariants() {
    if (!this.variants) {
      return;
    }
    // get the search keyword
    let search = this.refFilterCtrl.value;
    if (!search) {
      this.setFilteredArray();
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredVariants = this.variants.filter(function (item) {
      if (this.count < 25 && item.cDNADesignation.toLowerCase().indexOf(search) > -1) {
        this.count++;
        return true;
      }
      return false;
    }, { count: 0 });
  }

  update(): void {
    this.dialogRef.close(this.variants.find(variant => variant.VariantID == this.id));
  }

}
