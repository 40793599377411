export class ResearchSignificance {
  ResearchSignificanceID: number;
  Name: string;
  Description: string;
  ShortName: string;

  constructor(id: number, name: string) {
    this.ResearchSignificanceID = id;
    this.Name = name;
  }
}
