import { Variant } from './variant';

export class VariantUpload extends Variant {

  IsVerified: boolean;
  IsUploaded: boolean;
  Delete: boolean;
  Gene: string;
  Classification: string;
  VariantType: string;
  ClinicalSignificance: string;
  PredictedPenetrance: string;
  Region: string;
  PopulationData: string;
  PredictiveData: string;
  FunctionalData: string;
  SegregationData: string;
  DeNovoData: string;
  AlleleData: string;
  OtherDatabase: string;
  OtherData: string;

  ReferenceID: number;
  Reference: string;
  ReferenceURL: string;
  ReferenceFamily: string;

  Errors: string[];
  Warnings: string[];
}
