import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'adpkd-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  title: string = "";
  username: string = "";
  email: string = "";
  comments: string = "";
  message: string = "";
  private submitcount: number = 0;

  constructor() { }

  ngOnInit() {
  }


checkdata() {
  if (!this.checkfield(this.username, "Name")) return false;
  if (!this.checkemail(this.email, "E-mail")) return false;
  if (!this.checkfield(this.comments, "Comments")) return false;

  if (this.submitcount == 0) {
    this.submitcount++;
    this.message = "Thank you! Details sent successfully.\n\nDepending on your email application,\nyou may have to send the email\n(look for an open mail window).\n\nPlease press OK to continue to browse this site.";
    this.sendmail();
    return true;
  } else {
    this.message = "The details have already been submitted. Thank you. Please continue to browse the site.";
    return false;
  }
}

checkemail(field, desc) {
  field = this.trim(field);

  if (field == "") {
    this.message = desc + " has been left empty";
    return (false);
  }

  if (!this.validvalue(field, "abcdefghijklmnopqrstuvwxyz1234567890.-_@", 0)) {
    this.message = desc + " contains invalid characters";
    return (false);
  }

  if ((field.indexOf("@") == -1) || (field.indexOf(".") == -1)) {
    this.message = desc + " doesn't look like an e-mail address";
    return (false);
  }

  return (true);
}

checkfield(field, desc) {
  field = this.trim(field);

  if (field == "") {
    this.message = desc + " has been left empty";
    return false;
  }

  if (!this.validvalue(field, "abcdefghijklmnopqrstuvwxyz1234567890 .-,;:_@", 0)) {
    this.message = desc + " contains invalid characters";
    return false;
  }

  return true;
}

trim(value) {
  var startposn = 0;

  while ((value.charAt(startposn) == " ") && (startposn < value.length))
    startposn++;

  if (startposn == value.length) {
    value = ""
  } else {
    value = value.substring(startposn, value.length);
    var endposn = (value.length) - 1;

    while (value.charAt(endposn) == " ")
      endposn--;

    value = value.substring(0, endposn + 1);
  }
  return value;
}

validvalue(value, validchars, casesensitive) {

  if (casesensitive == 0) {
    value = value.toUpperCase();
    validchars = validchars.toUpperCase();
  }

  var charposn = 0;

  while ((charposn < value.length) && validchars.indexOf(value.charAt(charposn)) != -1)
    charposn++;

  if (charposn == value.length) {
    return true;
  } else {
    return false;
  }
}

sendmail() {
    var info = " From: " + this.title + " " + this.username + "\n" +
      " Email: " + this.email + "\n" + " Comments: " + this.comments;
  
    var link = "mailto:PKDCenter%40mayo.edu?subject=" + escape('PKD Feedback') + "&body=" + escape(info);

    window.location.href = link;
  }

}
