import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { VariantService } from '@app/services/variant.service';
import { VariantReference } from '@app/models/variantReference';
import { Gene } from '@app/models/gene';
import { Region } from '@app/models/region';
import { Classification } from '@app/models/classification';
import { VariantType } from '@app/models/variantType';
import { ClinicalSignificance } from '@app/models/clinicalSignificance';
import { PopulationData } from '@app/models/populationData';
import { PredictiveData } from '@app/models/predictiveData';
import { FunctionalData } from '@app/models/functionalData';
import { SegregationData } from '@app/models/segregationData';
import { DeNovoData } from '@app/models/deNovoData';
import { AlleleData } from '@app/models/alleleData';
import { OtherDatabase } from '@app/models/otherDatabase';
import { OtherData } from '@app/models/otherData';
import { Variant } from '@app/models/variant';
import { SelectReferenceComponent } from '@app/popups/select-reference/select-reference.component';
import { AuthService } from '@app/services/auth.service';
import { Reference } from '@app/models/reference';
import { Family } from '@app/models/family';
import { ConfirmationDialogComponent } from '@app/popups/confirmation-dialog/confirmation-dialog.component';
import { UnpublishedReferenceComponent } from '@app/popups/unpublished-reference/unpublished-reference.component';
import { PredictedPenetrance } from '@app/models/predictedPenetrance';
import { throwError } from 'rxjs';
import { AlertsService } from '@app/services/alerts.service';
import { VariantChange } from '@app/models/variantChange';
import {ResearchSignificance} from '@app/models/researchSignificance';

@Component({
  selector: 'adpkd-variant-details',
  templateUrl: './variant-details.component.html',
  styleUrls: ['./variant-details.component.scss'],

})
export class VariantDetailsComponent implements OnInit {
  allColumns = ['Row', 'Title', 'Family'];
  minColumns = ['Row', 'Title'];
  displayedColumns = ['Row', 'Title', 'Family'];
  changeColumns = ['Row', 'Field', 'NewValue', 'OldValue', 'ChangeType', 'ModifyingUser'];
  dataSource: MatTableDataSource<VariantReference>;
  changeSource: MatTableDataSource<VariantChange>;
  id: number;
  dictionaryId: number = null;
  variant: Variant;
  genes: Gene[];
  classifications: Classification[];
  variantTypes: VariantType[];
  clinicalSignificances: ClinicalSignificance[];
  researchSignificances: ResearchSignificance[];
  predictedPenetrances: PredictedPenetrance[];
  regions: Region[];
  populations: PopulationData[];
  predictives: PredictiveData[];
  functionals: FunctionalData[];
  segregations: SegregationData[];
  deNovos: DeNovoData[];
  alleles: AlleleData[];
  otherDatabases: OtherDatabase[];
  others: OtherData[];
  changes: VariantChange[];
  editing = false;
  canEdit = false;
  oldVersion = false;
  errorMessage: string;
  mode: string;
  canEditVariant: boolean;
  private sort: MatSort;

  constructor(private dialogRef: MatDialogRef<VariantDetailsComponent>,
              private service: VariantService,
              @Inject(MAT_DIALOG_DATA) data,
              private dialog: MatDialog,
              public authService: AuthService,
              private alerts: AlertsService) {

    this.mode = data.mode;

    this.canEdit = this.authService.isEditor;
    this.oldVersion = data.old;

    if (this.mode === 'edit') {
      this.id = data.id;
    }

    if (this.mode === 'add') {
      this.editing = this.authService.isEditor;
      this.canEdit = false;
    }

    if (this.mode === 'show') {
      this.editing = false;
      this.id = data.id;
      this.dictionaryId = data.dictionaryId;
    }

    this.dataSource = new MatTableDataSource<VariantReference>([]);
    this.changeSource = new MatTableDataSource<VariantChange>([]);
  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.changeSource.sort = this.sort;
  }

  ngOnInit() {

    if (this.id) {
      this.service.getVariantByID(this.id, this.dictionaryId).subscribe({
        next: variant => {
          this.variant = variant;

          if (!this.canShowFamily() && (this.mode === 'show' || this.mode === 'edit') && this.editing === false) {
            this.displayedColumns = this.minColumns;
          }

          this.canEditVariant = this.service.CanAddEditVariants();

          if (this.variant.VariantReferences != null) {
            this.dataSource.data = this.variant.VariantReferences;
          }
        },
        error: err => this.errorMessage = err
      });
    }
    else {
      this.variant = new Variant();
      this.variant.VariantID = -1;
      this.variant.VariantReferences = new Array<VariantReference>();
    }

    this.service.getGenes().subscribe({
      next: genes => {
        this.genes = genes;
      },
      error: err => this.errorMessage = err
    });

    this.service.getClassifications().subscribe({
      next: classifications => {
        this.classifications = classifications;
      },
      error: err => this.errorMessage = err
    });

    this.service.getVariantTypes().subscribe({
      next: variantTypes => {
        this.variantTypes = variantTypes;
      },
      error: err => this.errorMessage = err
    });

    this.service.getClinicalSignificances().subscribe({
      next: clinicalSignificances => {
        this.clinicalSignificances = clinicalSignificances;
      },
      error: err => this.errorMessage = err
    });

    this.service.getResearchSignificances().subscribe({
      next: researchSignificances => {
        this.researchSignificances = researchSignificances;
      },
      error: err => this.errorMessage = err
    });

    this.service.getPredictedPenetrances().subscribe({
      next: predictedPenetrances => {
        this.predictedPenetrances = predictedPenetrances;
      },
      error: err => this.errorMessage = err
    });

    this.service.getRegions().subscribe({
      next: regions => {
        this.regions = regions;
      },
      error: err => this.errorMessage = err
    });

    this.service.getPopulationData().subscribe({
      next: populations => {
        this.populations = populations;
      },
      error: err => this.errorMessage = err
    });

    this.service.getPredictiveData().subscribe({
      next: predictives => {
        this.predictives = predictives;
      },
      error: err => this.errorMessage = err
    });

    this.service.getFunctionalData().subscribe({
      next: functionals => {
        this.functionals = functionals;
      },
      error: err => this.errorMessage = err
    });

    this.service.getSegregationData().subscribe({
      next: segregations => {
        this.segregations = segregations;
      },
      error: err => this.errorMessage = err
    });

    this.service.getDeNovoData().subscribe({
      next: deNovos => {
        this.deNovos = deNovos;
      },
      error: err => this.errorMessage = err
    });

    this.service.getAlleleData().subscribe({
      next: alleles => {
        this.alleles = alleles;
      },
      error: err => this.errorMessage = err
    });

    this.service.getOtherDatabase().subscribe({
      next: otherDatabases => {
        this.otherDatabases = otherDatabases;
      },
      error: err => this.errorMessage = err
    });

    this.service.getOtherData().subscribe({
      next: others => {
        this.others = others;
      },
      error: err => this.errorMessage = err
    });

    if (this.authService.isEditor && this.id && this.mode === 'edit') {
      this.service.getVariantChangesByID(this.id).subscribe({
        next: changes => {
          this.changes = changes;

          if (this.changes != null) {
            this.changeSource.data = this.changes;
          }
        },
        error: err => this.errorMessage = err
      });
    }
  }

  openSelectReference(reference: VariantReference) {
    const dialogRef = this.dialog.open(SelectReferenceComponent, { data: { mode: 'update' , id: reference.ReferenceID },
      disableClose: false, maxWidth: '95%', maxHeight: '95%' });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        const ref: Reference = result as Reference;

        reference.Description = ref.Description;
        reference.ReferenceID = ref.ReferenceID;
        reference.Title = ref.Title;
        reference.Unpublished = ref.Unpublished;
        reference.URL = ref.URL;
      }
    });
  }

  saveVariant(): void {

    if (this.mode === 'edit') {
      this.service.updateVariant(this.variant).subscribe((resp) => {
        this.dialogRef.close(this.variant);
      },
        (error) => {
          this.alerts.ShowError(error);
          this.dialogRef.close();
        }
      );
    }

    if (this.mode === 'add') {

      this.service.addVariant(this.variant).subscribe((resp) => {
        this.dialogRef.close(this.variant);
      },
        (error) => {
          this.alerts.ShowError(error);
          this.dialogRef.close();
        }
      );
    }
  }

  deleteVariant(): void {

    if (this.mode === 'edit') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent,
        { data: { title: 'Delete Variant', message: 'Are you sure you wish to delete this variant?' }, disableClose: true,
          maxWidth: '95%', maxHeight: '95%' });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.service.deleteVariant(this.variant).subscribe((resp) => {
            this.dialogRef.close('Variant Deleted');
          },
            (error) => {
              this.alerts.ShowError(error);
              this.dialogRef.close();
            });
        }
      });
    }
  }

  addFamily(reference: VariantReference) {
    reference.Familys.push(new Family());
  }

  removeFamily(reference: VariantReference, family: Family) {

    const index = reference.Familys.indexOf(family, 0);
    if (index > -1) {
      reference.Familys.splice(index, 1);
    }
  }

  addReference() {
    const dialogRef = this.dialog.open(SelectReferenceComponent, { data: {mode: 'add', variant: this.variant }, disableClose: false,
      maxWidth: '95%', maxHeight: '95%' }
    );

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        const ref: Reference = result as Reference;

        const reference: VariantReference = new VariantReference();

        reference.Description = ref.Description;
        reference.ReferenceID = ref.ReferenceID;
        reference.Title = ref.Title;
        reference.Unpublished = ref.Unpublished;
        reference.URL = ref.URL;
        reference.Familys = new Array<Family>();
        reference.VariantID = this.variant.VariantID;

        this.variant.VariantReferences.push(reference);

        if (this.variant.VariantReferences != null) { this.dataSource.data = this.variant.VariantReferences; }
      }
    });
  }

  removeReference(reference: VariantReference) {

    const index = this.variant.VariantReferences.indexOf(reference, 0);
    if (index > -1) {
      this.variant.VariantReferences.splice(index, 1);

      if (this.variant.VariantReferences != null) { this.dataSource.data = this.variant.VariantReferences; }
    }
  }

  showUnpublishedReference(reference: Reference) {
    const dialogRef = this.dialog.open(UnpublishedReferenceComponent, { data: { reference }, disableClose: true, width: '105%',
      height: '95%' });
  }

  regionChanged() {

    if (this.variant.RegionEndID < this.variant.RegionStartID) {
      this.variant.RegionEndID = this.variant.RegionStartID;
    }

    if (this.variant.RegionStartID != null) {
      if (this.variant.RegionEndID != null) {
        if (this.variant.RegionStartID == this.variant.RegionEndID) {
          this.variant.RegionDisplay = this.regions.find(region => region.RegionID === this.variant.RegionStartID).Display;
        }
        else {
          this.variant.RegionDisplay = this.regions.find(region => region.RegionID === this.variant.RegionStartID).Display + '-' +
            this.regions.find(region => region.RegionID == this.variant.RegionEndID).Display;
        }
      }
      else {
        this.variant.RegionDisplay = this.regions.find(region => region.RegionID === this.variant.RegionStartID).Display;
      }
    }
    else {
      if (this.variant.RegionEndID != null) {
        this.variant.RegionDisplay = this.regions.find(region => region.RegionID === this.variant.RegionEndID).Display;
      }
      else {
        this.variant.RegionDisplay = '';
      }
    }
  }

  regionOpened(value: boolean) {

    if (!value) {
      if (this.variant.RegionEndID < this.variant.RegionStartID) {
        this.variant.RegionEndID = this.variant.RegionStartID;
      }

      if (this.variant.RegionStartID != null) {
        if (this.variant.RegionEndID != null) {
          if (this.variant.RegionStartID === this.variant.RegionEndID) {
            this.variant.RegionDisplay = this.regions.find(region => region.RegionID === this.variant.RegionStartID).Display;
          }
          else {
            this.variant.RegionDisplay = this.regions.find(region => region.RegionID === this.variant.RegionStartID).Display + '-' +
              this.regions.find(region => region.RegionID === this.variant.RegionEndID).Display;
          }
        }
        else {
          this.variant.RegionDisplay = this.regions.find(region => region.RegionID === this.variant.RegionStartID).Display;
        }
      }
      else {
        if (this.variant.RegionEndID != null) {
          this.variant.RegionDisplay = this.regions.find(region => region.RegionID === this.variant.RegionEndID).Display;
        }
        else {
          this.variant.RegionDisplay = '';
        }
      }
    }
  }

  validReferences(): boolean {

    return !this.variant.VariantReferences.some(ref => ref.Familys.some(fam => fam.FamilyName == null || fam.FamilyName?.length === 0));
  }

  canShowFamily(): boolean {

    return this.variant.ClinicalSignificanceName !== 'Benign' && this.variant.ClinicalSignificanceName !== 'Likely Benign';
  }
}
