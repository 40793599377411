import { Reference } from './reference';

export class ReferenceUpload extends Reference {

  IsVerified: boolean;
  IsUploaded: boolean;
  Delete: boolean;

  Errors: string[];
  Warnings: string[];
}
