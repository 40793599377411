export class VariantType {
  VariantTypeID: number;
  Name: string;
  Description: string;

  constructor(id: number, name: string) {
    this.VariantTypeID = id;
    this.Name = name;
  }
}
