import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { VariantService } from '../../services/variant.service';
import { Reference } from '../../models/reference';
import { Unpublished } from '../../models/unpublished';

@Component({
  selector: 'adpkd-unpublished-reference',
  templateUrl: './unpublished-reference.component.html',
  styleUrls: ['./unpublished-reference.component.scss']
})
export class UnpublishedReferenceComponent implements OnInit {
  errorMessage: string;
  reference: Reference;
  rows: any = [];

  constructor(private dialogRef: MatDialogRef<UnpublishedReferenceComponent>,
    private service: VariantService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog) {

    this.reference = data.reference;
  }

  ngOnInit(): void {

    this.service.getUnpublishedReferenceByID(this.reference.ReferenceID).subscribe({
      next: columns => {

        for (var i = 0; i < columns.length; i+=5) {
          this.rows.push(columns.slice(i, i+5));
        }
      },
      error: err => this.errorMessage = err
    });
  }

}
