import { Component, OnInit } from '@angular/core';
import { VariantService } from '../services/variant.service';
import { Reference } from '../models/reference';
import { SpinnerDialogComponent } from '../popups/spinner-dialog/spinner-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Gene } from '../models/gene';
import { Classification } from '../models/classification';
import { VariantType } from '../models/variantType';
import { ClinicalSignificance } from '../models/clinicalSignificance';
import { PredictedPenetrance } from '../models/predictedPenetrance';
import { PopulationData } from '../models/populationData';
import { PredictiveData } from '../models/predictiveData';
import { FunctionalData } from '../models/functionalData';
import { SegregationData } from '../models/segregationData';
import { DeNovoData } from '../models/deNovoData';
import { AlleleData } from '../models/alleleData';
import { OtherDatabase } from '../models/otherDatabase';
import { OtherData } from '../models/otherData';
import { Variant } from '../models/variant';

@Component({
  selector: 'app-data-download',
  templateUrl: './data-download.component.html',
  styleUrls: ['./data-download.component.scss']
})
export class DataDownloadComponent implements OnInit {
  downloadTypes: string[] = ['Gene', 'Classification', 'Variant Type', 'Clinical Significance', 'Predicted Penetrance', 'Population Data', 'Predictive Data', 'Functional Data',
                             'Segregation Data', 'DeNovo Data', 'Allele Data', 'Other Database', 'Other Data', 'Reference', 'Variants'];
  selectedType: string = 'Gene';
  dialogRef: MatDialogRef<SpinnerDialogComponent>;

  constructor(public service: VariantService, private dialog: MatDialog) { }

  ngOnInit(): void {}

  download() {
    // Show Loading Popup
    this.dialogRef = this.dialog.open(SpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });    

    switch (this.selectedType) {
      case 'Gene': {
        let objects: Gene[];

        this.service.getGenes().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Classification': {
        let objects: Classification[];

        this.service.getClassifications().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Variant Type': {
        let objects: VariantType[];

        this.service.getVariantTypes().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Clinical Significance': {
        let objects: ClinicalSignificance[];

        this.service.getClinicalSignificances().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Predicted Penetrance': {
        let objects: PredictedPenetrance[];

        this.service.getPredictedPenetrances().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Population Data': {
        let objects: PopulationData[];

        this.service.getPopulationData().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Predictive Data': {
        let objects: PredictiveData[];

        this.service.getPredictiveData().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Functional Data': {
        let objects: FunctionalData[];

        this.service.getFunctionalData().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Segregation Data': {
        let objects: SegregationData[];

        this.service.getSegregationData().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'DeNovo Data': {
        let objects: DeNovoData[];

        this.service.getDeNovoData().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Allele Data': {
        let objects: AlleleData[];

        this.service.getAlleleData().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Other Database': {
        let objects: OtherDatabase[];

        this.service.getOtherDatabase().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Other Data': {
        let objects: OtherData[];

        this.service.getOtherData().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Reference': {
        let objects: Reference[];

        this.service.getReferences().subscribe({
          next: value => {
            objects = value;

            objects.forEach(object => {
              delete object.Description;
              delete object.UnpublishedReference;
            });

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
      case 'Variants': {
        let objects: Variant[];

        this.service.getVariants().subscribe({
          next: value => {
            objects = value;

            this.service.downloadBlobFile(this.service.convertObjectToCSV(objects), this.getFileName(this.selectedType));

            this.dialogRef.close();
          }
        });

        break;
      }
    } 
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  getFileName(name: string): string {
    var d = new Date();
    var mo = this.addZero(d.getMonth() + 1);
    var yr = this.addZero(d.getFullYear());
    var dt = this.addZero(d.getDate());
    var h = this.addZero(d.getHours());
    var m = this.addZero(d.getMinutes());
    var s = this.addZero(d.getSeconds());

    return (name + '_' + mo + dt + yr + '_' + h + m + s + ".csv");
  }
}
