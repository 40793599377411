export class Region {
  RegionID: number;
  Value: string;
  Display: string;

  constructor(id: number, value: string, display: string) {
    this.RegionID = id;
    this.Value = value;
    this.Display = display;
  }
}
