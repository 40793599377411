import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import {filter} from 'rxjs/operators';

declare const gtag: Function;

@Component({
  selector: 'adpkd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  pageTitle = 'Autosomal Dominant Polycystic Kidney Disease Variant Database';

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private router: Router) {

    iconRegistry.addSvgIcon('pkd-title-svg', sanitizer.bypassSecurityTrustResourceUrl('assets/images/black_H_RGB.svg'));
    iconRegistry.addSvgIcon('warn-outline', sanitizer.bypassSecurityTrustResourceUrl('assets/images/warn_24px.svg'));

    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      });
    });
    /** END : Code to Track Page View  using gtag.js */
  }
}
