import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { VariantService } from '../../services/variant.service';
import { MatTableDataSource } from '@angular/material/table';
import { Variant } from '../../models/variant';
import { Dictionary } from '../../models/dictionary';
import { VariantDetailsComponent } from '../../base-page/variant-details/variant-details.component';

@Component({
  selector: 'adpkd-dictionary-publish',
  templateUrl: './dictionary-publish.component.html',
  styleUrls: ['./dictionary-publish.component.scss']
})
export class DictionaryPublishComponent implements OnInit {
  displayedColumns = ['Row', 'cDNADesignation', 'AminoAcidDesignation', 'VariantTypeName', 'ClinicalSignificanceName', 'PredictedPenetranceName'];
  id: number;
  dictionary: Dictionary;
  removeSource: MatTableDataSource<Variant> = new MatTableDataSource;
  addSource: MatTableDataSource<Variant> = new MatTableDataSource;
  updateSource: MatTableDataSource<Variant> = new MatTableDataSource;
  isLoading: boolean = true;
  errorMessage: string;  

  constructor(private dialogRef: MatDialogRef<DictionaryPublishComponent>,
              private service: VariantService,
              @Inject(MAT_DIALOG_DATA) data,
              private dialog: MatDialog,
              private authService: AuthService) {

    this.id = data.id;
  }

  ngOnInit(): void {

    this.isLoading = true;

    this.service.getDictionaryByID(this.id).subscribe({
      next: dictionary => {
        this.dictionary = dictionary;
      },
      error: err => this.errorMessage = err
    });

    this.service.getDictionaryChangesFromPublishedByID(this.id).subscribe({
      next: changes => {
        this.isLoading = false;

        this.removeSource.data = changes.Removed;
        this.addSource.data = changes.Added;
        this.updateSource.data = changes.Updated;
      },
      error: err => {
        this.errorMessage = err;
        this.isLoading = true;
      }
    });
  }

  showVariant(id: number) {
    let dialogRef = this.dialog.open(VariantDetailsComponent, { data: { mode: 'show', id: id, dictionaryId: this.id }, disableClose: true, width: '105%', height: '95%', maxHeight: '95%' }
    );

    dialogRef.afterClosed().subscribe();
  }

  publishDictionary() {

    this.service.publishDictionary(this.dictionary).subscribe(() => {
      this.service.loadDictionaries();
      this.dialogRef.close(this.dictionary);
    });
  }
}
