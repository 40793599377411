import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertsService } from '../services/alerts.service';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertsService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401,403].indexOf(err.status) !== -1) {
        this.alertService.ShowError("You are not authorized to access this component: " + err.message || err.error.message || err.statusText);
        this.router.navigateByUrl('/welcome');
      }

      var error = (err.message || err.error.message || err.statusText) + '; ';

      if (err.error) {
        if (err.error.ModelState) {
          Object.keys(err.error.ModelState).forEach(e => error += err.error.ModelState[e] + '; ');

          return throwError(error);
        }
      }

      return throwError(err);
    }))
  }
}
