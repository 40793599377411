import { Component, OnInit } from '@angular/core';
import { News } from '../../models/news';
import { VariantService } from '../../services/variant.service';

@Component({
  selector: 'adpkd-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  news: News[];

  constructor(private service: VariantService ) { }

  ngOnInit(): void {
    this.service.getNews().subscribe({
      next: news => {
        this.news = news
      }
    });
  }
}
