import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VariantService } from '../../services/variant.service';
import { Reference } from '../../models/reference';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { Variant } from '../../models/variant';


@Component({
  selector: 'adpkd-select-reference',
  templateUrl: './select-reference.component.html',
  styleUrls: ['./select-reference.component.scss']
})
export class SelectReferenceComponent implements OnInit {
  references: Reference[];
  filteredReferences: Reference[];
  errorMessage: string;
  mode: string;
  id: number;
  variant: Variant; 

  /** control for the MatSelect filter keyword */
  public refFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<SelectReferenceComponent>, private service: VariantService, @Inject(MAT_DIALOG_DATA) data) {
    this.mode = data.mode;


    if (this.mode == 'update') {
      this.id = data.id;
    }

    if (this.mode == 'add') {
      this.variant = data.variant;
    }
  }

  ngOnInit(): void {

    this.service.getReferences().subscribe({
      next: references => {
        this.references = references
        this.filteredReferences = this.references;

        // listen for search field value changes
        this.refFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterReferences();
          });
      },
      error: err => this.errorMessage = err
    });

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterReferences() {
    if (!this.references) {
      return;
    }
    // get the search keyword
    let search = this.refFilterCtrl.value;
    if (!search) {
      this.filteredReferences = this.references;
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredReferences = this.references.filter(ref => ref.Title.toLowerCase().indexOf(search) > -1);
  }

  update(): void {    
      this.dialogRef.close(this.references.find(ref => ref.ReferenceID == this.id));
  }

}

