import { Family } from './family';
import { Unpublished } from './unpublished';

export class VariantReference {

  VariantReferenceID: number;
  VariantID: number;
  ReferenceID: number;
  Title: string;
  Description: string;
  URL: string;
  Unpublished: boolean;
  UnpublishedColumns: Unpublished[];
  Familys: Family[];
}
