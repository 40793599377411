import { Component, OnInit, Input } from '@angular/core';
import { BulkUpload } from '../../models/bulkUpload';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { VariantService } from '../../services/variant.service';
import { AlertsService } from '../../services/alerts.service';
import { ReferenceUpload } from '../../models/referenceUpload';
import { BulkUploadReferences } from '../../models/bulkUploadReferences';
import { SpinnerDialogComponent } from '../../popups/spinner-dialog/spinner-dialog.component';

@Component({
  selector: 'adpkd-reference-upload',
  templateUrl: './reference-upload.component.html',
  styleUrls: ['./reference-upload.component.scss']
})
export class ReferenceUploadComponent implements OnInit {
  componentType = "Reference"

  columns = ['Remove', 'Warning', 'Error', 'Saved', 'Title', 'URL'];
  
  errorMessage: string;
  @Input() bulkUpload: BulkUpload;
  bulkUploadReferences: BulkUploadReferences;

  filteredReferences: ReferenceUpload[];

  isChecked: boolean = false;
  isWarn: boolean = false;
  isError: boolean = false;
  isSave: boolean = false;
  isChanged: boolean = true;

  dialogRef: MatDialogRef<SpinnerDialogComponent>;

  source: MatTableDataSource<ReferenceUpload> = new MatTableDataSource;

  constructor(private service: VariantService, private alerts: AlertsService, private dialog: MatDialog) {

  }

  ngOnInit(): void {

    this.bulkUploadReferences = new BulkUploadReferences();
    this.bulkUploadReferences.References = new Array<ReferenceUpload>();

    this.source = new MatTableDataSource<ReferenceUpload>(this.bulkUploadReferences.References);
  }

  ngOnChanges() {

    this.ngOnInit();
    this.isChecked = false;
    this.isWarn = false;
    this.isError = false;
    this.isSave = false;
    this.isChanged = true;
  }

  add() {
    let reference = new ReferenceUpload;

    reference.Warnings = new Array<string>();
    reference.Errors = new Array<string>();
    reference.Errors.push("Name is missing!");

    reference.IsVerified = false;

    this.bulkUploadReferences.References.push(reference);

    this.filter();

    this.isChanged = true;
  }

  remove() {
    this.bulkUploadReferences.References = this.bulkUploadReferences.References.filter(reference => !reference.Delete)

    this.isChecked = false;
    this.isWarn = false;
    this.isError = false;
    this.isSave = false;

    this.filter();

    this.isChanged = true;
  }

  reset() {
    this.isChecked = false;
    this.isWarn = false;
    this.isError = false;
    this.isSave = false;
    this.isChanged = true;

    this.bulkUploadReferences.References.length = 0;
  }

  load(bulkUploadReferences: BulkUploadReferences) {

    this.bulkUploadReferences = bulkUploadReferences;
    this.filter();
    this.isChanged = true;
  }

  filter() {

    this.filteredReferences = this.bulkUploadReferences.References;

    if (this.isWarn == true) {
      this.filteredReferences = this.filteredReferences.filter(reference => reference.Warnings.length > 0);
    }

    if (this.isError == true) {
      this.filteredReferences = this.filteredReferences.filter(reference => reference.Errors.length > 0);
    }

    if (this.isSave == true) {
      this.filteredReferences = this.filteredReferences.filter(reference => reference.IsUploaded);
    }

    if (this.filteredReferences != null) { this.source.data = this.filteredReferences; }
  }

  selectAll(checked: boolean) {
    this.filteredReferences.forEach(reference => reference.Delete = checked);

    if (this.filteredReferences != null) { this.source.data = this.filteredReferences; }
  }

  showMessage(messages: string[]) {
    let message: string = "<table>";

    messages.forEach(value => {

      message += "<tr><td>" + value + "</td></tr>";
    });

    message += "</table>";

    return message;
  }

  showPopover(popover, object: string[]) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ object });
    }
  }

  selectionChanged(reference: ReferenceUpload, field: string, value: any) {

    if (reference.Errors.some(err => err.indexOf('Reference Already Exists!') > -1)) {
      const index = reference.Errors.indexOf('Reference Already Exists!', 0);

      if (index > -1) {
        reference.Errors.splice(index, 1);
      }
    }

    if (value == null || value == '') {
      this.addErrorField(reference, field);
    }
    else {
      this.removeErrorField(reference, field);
    }

    reference.IsVerified = false;
    this.isChanged = true;
  }

  errorFieldExists(reference: ReferenceUpload, field: string) {
    return reference.Errors.some(err => err.indexOf(field + ' is missing!') > -1);
  }

  addErrorField(reference: ReferenceUpload, field: string) {

    if (!this.errorFieldExists(reference, field)) {
      reference.Errors.push(field + " is missing!");
    }
  }

  removeErrorField(reference: ReferenceUpload, field: string) {

    const index = reference.Errors.indexOf(field + " is missing!", 0);

    if (index > -1) {
      reference.Errors.splice(index, 1);
    }
  }

  haveWarnings() {

    if (this.filteredReferences == null) {
      return false;
    }

    return this.filteredReferences.some(reference => reference.Warnings.length > 0);
  }

  haveErrors() {

    if (this.filteredReferences == null) {
      return false;
    }

    return this.filteredReferences.some(reference => reference.Errors.length > 0);
  }

  haveSaves() {

    if (this.filteredReferences == null) {
      return false;
    }

    return this.filteredReferences.some(reference => reference.IsUploaded == true);
  }

  anyChecked() {

    if (this.filteredReferences == null) {
      return false;
    }

    return this.filteredReferences.some(reference => reference.Delete == true);
  }

  canVerify() {
    return !this.bulkUploadReferences.References.some(reference => reference.Errors.length > 0) && this.bulkUploadReferences.References.length > 0 && !this.bulkUploadReferences.IsComplete && this.isChanged;
  }

  verify() {

    this.dialogRef = this.dialog.open(SpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });

    this.bulkUploadReferences.Description = this.bulkUpload.Description;
    this.bulkUploadReferences.UploadTypeID = this.bulkUpload.UploadTypeID;

    this.service.verifyBulkUploadReferences(this.bulkUploadReferences).subscribe({
      next: value => {

        this.bulkUploadReferences = value;
        this.filter();

        this.isChanged = false;

        this.dialogRef.close();
      },
      error: err => {
        this.errorMessage = err;
        this.alerts.ShowError(err);
        this.dialogRef.close();
      }
    });    
  }

  canUpload() {

    return !this.bulkUploadReferences.References.some(reference => reference.IsVerified == false || reference.IsVerified == null) &&
                      this.bulkUploadReferences.References.length > 0 && !this.bulkUploadReferences.IsComplete && !this.isChanged;
  }

  upload() {

    if (!this.bulkUploadReferences.IsComplete) {

      this.dialogRef = this.dialog.open(SpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true
      });

      this.bulkUploadReferences.Description = this.bulkUpload.Description;
      this.bulkUploadReferences.UploadTypeID = this.bulkUpload.UploadTypeID;

      this.service.uploadBulkUploadReferences(this.bulkUploadReferences).subscribe({
        next: value => {

          this.bulkUploadReferences = value;
          this.bulkUpload.IsComplete = this.bulkUploadReferences.IsComplete;
          this.filter();
          this.dialogRef.close();
        },
        error: err => {
          this.errorMessage = err;
          this.alerts.ShowError(err);
          this.dialogRef.close();
        }
      });
    }
  }
}
