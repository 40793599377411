import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { VariantService } from '../services/variant.service';
import { AlertsService } from '../services/alerts.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from '../popups/spinner-dialog/spinner-dialog.component';
import { MatStepper } from '@angular/material/stepper';
import { BulkUpload } from '../models/bulkUpload';
import { UploadType } from '../models/uploadType';
import { FileUpload } from '../models/fileUpload';
import { VariantUploadComponent } from './variant-upload/variant-upload.component';
import { ReferenceUploadComponent } from './reference-upload/reference-upload.component';
import { ReferenceVariantsUploadComponent } from './reference-variants-upload/reference-variants-upload.component';

@Component({
  selector: 'adpkd-bulk-load',
  templateUrl: './bulk-load.component.html',
  styleUrls: ['./bulk-load.component.scss']
})
export class BulkLoadComponent implements OnInit {
  errorMessage: string;
  bulkUpload: BulkUpload;
  bulkUploads: BulkUpload[];
  file: any;
  dialogRef: MatDialogRef<SpinnerDialogComponent>;
  uploadTypes: UploadType[];
  isValidDescription: boolean = true;
  navigationSubscription;

  @ViewChild(VariantUploadComponent) variantUpload: VariantUploadComponent;
  @ViewChild(ReferenceUploadComponent) referenceUpload: ReferenceUploadComponent;
  @ViewChild(ReferenceVariantsUploadComponent) variantReferenceUpload: ReferenceVariantsUploadComponent;

  constructor(private service: VariantService, private alerts: AlertsService, private dialog: MatDialog) {

  }
   
  ngOnInit(): void {

    this.bulkUpload = new BulkUpload;
    this.bulkUpload.UploadTypeID = 1;
    this.isValidDescription = true
    this.file = null;

    this.service.getBulkUploads().subscribe({
      next: bulkUploads => {
        this.bulkUploads = bulkUploads;
      },
      error: err => this.errorMessage = err
    });
    
    this.service.getUploadTypes().subscribe({
      next: uploadTypes => {
        this.uploadTypes = uploadTypes
      },
      error: err => this.errorMessage = err
    });
  }

  checkUploadDescription() {

    if (this.bulkUpload.Description) {
      this.bulkUpload.Description = this.bulkUpload.Description.trimLeft();

      this.isValidDescription = !this.bulkUploads.some(upload => upload.Description.trim() == this.bulkUpload.Description.trim());
    }
    else {
      this.isValidDescription = true;
    }
  }

  onFileChange(ev) {
    if (this.validateFile(ev.target.files[0].name)) {
      this.file = ev.target.files[0];

      ev.srcElement.value = null;
    }
    else {
      ev.srcElement.value = null;
      this.alerts.ShowError("Invalid file format.  Please only upload '.xlsx' files");
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'xlsx') {
      return true;
    }
    else {
      return false;
    }
  }
  
  loadExcel(stepper: MatStepper) {
    const reader = new FileReader();

    this.dialogRef = this.dialog.open(SpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });

    /* RESET Tables prior to reading file */
    switch (this.bulkUpload.UploadTypeID) {
      case 1:
        this.variantUpload.reset();
        break;
      case 2:
        this.referenceUpload.reset();
        break;
      case 3:
        this.variantReferenceUpload.reset();
        break;
    }

    reader.onload = () => {
      var file: FileUpload = new FileUpload;

      file.FileAsBase64 = reader.result.toString();

      this.service.uploadExcelFile(this.bulkUpload.UploadTypeID, file).subscribe(val => {

        /* CALL load function within specific component  */
        switch (this.bulkUpload.UploadTypeID) {
          case 1:
            this.variantUpload.load(val);
            break;
          case 2:
            this.referenceUpload.load(val);
            break;
          case 3:
            this.variantReferenceUpload.load(val);
            break;
        }

        this.dialogRef.close();
        stepper.next();
      });
    }

    reader.readAsDataURL(this.file);
  }

  startNewUpload(stepper: MatStepper) {

    stepper.reset();
    this.ngOnInit();
  }

  uploadTypeChanged() {
    this.file = null;
  }
}
