export class Gene {
  GeneID: number;
  Name: string;
  Description: string;

  constructor(id: number, name: string) {
    this.GeneID = id;
    this.Name = name;
  }
}
