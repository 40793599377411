import { Component, OnInit, ViewChild } from '@angular/core';
import { Reference } from '../models/reference';
import { MatTableDataSource } from '@angular/material/table';
import { VariantService } from '../services/variant.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ReferenceDetailComponent } from './reference-detail/reference-detail.component';
import { AuthService } from '../services/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { UnpublishedReferenceComponent } from '../popups/unpublished-reference/unpublished-reference.component';
import { AlertsService } from '../services/alerts.service';

@Component({
  selector: 'adpkd-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {
  displayedColumns = ['Row', 'Title', 'URL'];
  dataSource: MatTableDataSource<Reference> = new MatTableDataSource;
  errorMessage: string;
  isLoading: boolean = true;
  search: string;
  references: Reference[];

  constructor(private service: VariantService, private dialog: MatDialog, private alerts: AlertsService, public authService: AuthService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  ngOnInit() {
    this.loadReferences();
  }

  public loadReferences() {
    this.service.getReferences().subscribe({
      next: references => {
        this.isLoading = false;
        this.references = references

        this.dataSource.data = this.references;
        if (this.paginator) this.paginator.firstPage();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error: err => {
        this.errorMessage = err;
        this.isLoading = false;
      }
    });

    this.dataSource.filterPredicate = (data: Reference, filter: string) => {
        return data.Title.toLowerCase().indexOf(filter) !== -1;
    };
  }

  applySearch(searchValue: string) {
    searchValue = searchValue.trim();
    searchValue = searchValue.toLowerCase();
    this.search = searchValue;

    this.dataSource.filter = this.search;   
  }
  
  editReference(reference: Reference) {
    let dialogRef = this.dialog.open(ReferenceDetailComponent, { data: { mode: 'edit', id: reference.ReferenceID }, disableClose: true, width: '105%', height: '95%', maxHeight: '95%' }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log('Reference Details dialog was closed', result);

      if (result) {
        if (result == "Reference Deleted") {
          this.alerts.ShowNotify(result);
        }
        else {
          let reference: Reference = result as Reference;

          this.alerts.ShowNotify("Reference Updated: " + reference.Title);
        }

        this.loadReferences();        
      }
    });
  }

  addReference() {
    let dialogRef = this.dialog.open(ReferenceDetailComponent, { data: { mode: 'add' }, disableClose: true, width: '105%', height: '95%', maxHeight: '95%' });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Add Reference dialog was closed', result);

      if (result) {
        let reference: Reference = result as Reference;

        this.alerts.ShowSuccess("Reference Added: " + reference.Title);

        this.loadReferences();
      }
    });
  }

  showUnpublishedReference(reference: Reference) {
    let dialogRef = this.dialog.open(UnpublishedReferenceComponent, { data: { reference: reference }, disableClose: true, width: '105%', height: '95%' });
  }
}
