import { Injectable } from '@angular/core';
import { User, Group } from '../models/user';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { AlertsService } from './alerts.service';
import { Client } from '@microsoft/microsoft-graph-client';
import { Role } from '../models/role';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IdleDialogComponent } from '../popups/idle-dialog/idle-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authenticated: boolean;
  public user: User;
  private lastPing?: Date = null;

  private sessionIdle: number =  900;
  private sessionTimeout: number = 60;

  private _isAuthenticated = new BehaviorSubject<boolean>(false);

  get isAdmin() {
    if (!this.authenticated) { return false };

    if (this.user) {
      return this.user.isAdmin;
    }
    else {
      return false;
    }
  }

  get isPublisher() {
    if (!this.authenticated) { return false };

    if (this.user) {
      return this.user.isPublisher;
    }
    else {
      return false;
    }
  }

  get isEditor() {
    if (!this.authenticated) { return false };

    if (this.user) {
      return this.user.isEditor;
    }
    else {
      return false;
    }
  }

  isAuthenticated(): Observable<Boolean> {
    return this._isAuthenticated.asObservable();
  }

  constructor(
    private msalService: MsalService,
    private alertsService: AlertsService,
    private sanitizer: DomSanitizer,
    private idle: Idle,
    private keepalive: Keepalive,
    private dialog: MatDialog) {
          
    
    idle.setIdle(this.sessionIdle);    
    idle.setTimeout(this.sessionTimeout);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.signOut();
    });

    idle.onIdleStart.subscribe(() => {
      this.dialog.open(IdleDialogComponent, { disableClose: true, maxWidth: '95%', maxHeight: '95%' });
    });


    // sets the ping interval to .1 seconds
    keepalive.interval(.1);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());
       
    this.authenticated = this.msalService.getUser() != null;

    if (this.authenticated) {
      this.getUser().then((user) => {
        if (user) {          
          this.user = user;
          this._isAuthenticated.next(true);
          this.reset();
        }
        else {
          this.user = null;
          this._isAuthenticated.next(false);
          this.authenticated = false;
        }
      });
    } else {
      this._isAuthenticated.next(false);
    }

  }

  reset() {
    this.idle.watch();
  }

  async signIn(): Promise<void> {
    let result = await this.msalService.loginPopup(AppConfigService.settings.scopes)
      .catch((reason) => {
        this.alertsService.ShowError('Login failed: ' + JSON.stringify(reason, null, 2));
        console.error(JSON.stringify(reason, null, 2));
      });

    if (result) {
      //console.log(result);

      this.authenticated = true;
      this.user = await this.getUser();
      this._isAuthenticated.next(true);
      this.reset();
    }
  }

  // Sign out
  signOut(): void {
    this.msalService.logout();
    window.location.href = '#';

    this.user = null;
    this.authenticated = false;
    this._isAuthenticated.next(false);
  }

  // Silently request an access token
  async getGraphAccessToken(): Promise<string> {
    if (this.authenticated) {
      let result = await this.msalService.acquireTokenSilent(["user.read"])
        .catch((reason) => {
          //this.alertsService.add('Get token failed', JSON.stringify(reason, null, 2));
          this.alertsService.ShowError('Get token failed: ' + JSON.stringify(reason, null, 2));
        });

      //console.log(result);
      
      return result;
    } else {
      return null; 
    }
  }

  async getAdpkdAccessToken(): Promise<string> {
    if (this.authenticated && AppConfigService.settings) {
      let result = await this.msalService.acquireTokenSilent(AppConfigService.settings.adpkdScopes)
        .catch((reason) => {
          //this.alertsService.add('Get token failed', JSON.stringify(reason, null, 2));
          this.alertsService.ShowError('Get token failed: ' + JSON.stringify(reason, null, 2));
        });

      //console.log(result);
      return result;
    }
    else {
      return null;
    }
  }

  private async getUser(): Promise<User> {
    let user: User;

    if (!this.authenticated) return null;

    let graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async (done) => {
        let token = await this.getGraphAccessToken()
          .catch((reason) => {
            console.log("error getting token");
            done(reason, null);
            //return null;
          });

        if (token) {
          //console.log(token);
          done(null, token);
        } else {
          //console.log("no token");
          done("Could not get an access token", null);
          //return null;
        }
      }
    });

    try {
      // Get the user from Graph (GET /me)
      user = await graphClient.api('/me').get();
    } catch (e) {
      return null;
    }
    
    if (user) {

      // Add user groups and check for application roles
      var x = await this.getGroups().then(groups => {
        user.groups = groups;

        if (groups.length > 0) {          

          user.isAdmin = groups.some(group => group.displayName == 'ADPKD_Admin_'+AppConfigService.settings.env.toUpperCase());
          user.isPublisher = groups.some(group => group.displayName == 'ADPKD_Publisher_' + AppConfigService.settings.env.toUpperCase()) || user.isAdmin;
          user.isEditor = groups.some(group => group.displayName == 'ADPKD_Editor_' + AppConfigService.settings.env.toUpperCase()) || user.isAdmin || user.isPublisher;

          if (user.isAdmin) {
            user.role = Role.Admin;
          }
          else if (user.isPublisher) {
            user.role = Role.Publisher;
          }
          else if (user.isEditor) {
            user.role = Role.Editor;
          }
        }
      });

      // Add user photo
      var y = await this.getPhoto().then(photo => {
        user.photo = photo;
      });
    }
    else {
        return null;
    }       

    return user;
  }

  private async getGroups(): Promise<Group[]> {
    if (!this.authenticated) return null;

    let graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async (done) => {
        let token = await this.getGraphAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });

    let groups = await graphClient.api('/me/memberOf').get();
    return groups.value;
  }

  private async getPhoto(): Promise<SafeResourceUrl> {
    if (!this.authenticated) return null;

    let graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async (done) => {
        let token = await this.getGraphAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });

    let photo = await graphClient.api('/me/photo/$value').get();

    const url = window.URL || window.webkitURL;
    const blobUrl = url.createObjectURL(photo);

    return this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  }
}
