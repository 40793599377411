import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SubmissionsComponent } from '../../popups/submissions/submissions.component';
import { AcknowledgementsComponent } from '../../popups/acknowledgements/acknowledgements.component';
import { ContactComponent } from '../../popups/contact/contact.component';
import { NewsComponent } from '../../popups/news/news.component';

@Component({
  selector: 'adpkd-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router, private dialog: MatDialog) { }

  ngOnInit() {
  }

  openWelcome() {
    this.router.navigateByUrl('/welcome');
  }

  openNews() {
    this.dialog.open(NewsComponent, { disableClose: true, width: '99%', height: '95%' });    
  }

  openSubmissions() {
    this.dialog.open(SubmissionsComponent, { disableClose: true, width: '99%', height: '95%'});    
  }

  openAcknowledgements() {
    this.dialog.open(AcknowledgementsComponent, { disableClose: true, width: '99%', height: '95%' });
  }

  openContact() {
    this.dialog.open(ContactComponent, { disableClose: true, width: '99%', height: '95%' });
  }
}
