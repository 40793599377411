import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private snackBar: MatSnackBar, private router: Router) { }

  ShowSuccess(message: string) {
    let config = new MatSnackBarConfig();

    config.panelClass = 'snack-bar-success';
    config.duration = 5000;

    this.openSnackBar(message, "", config).onAction().subscribe();
  }

  ShowNotify(message: string) {
    let config = new MatSnackBarConfig();

    config.panelClass = 'snack-bar-notify';
    config.duration = 5000;

    this.openSnackBar(message, "", config).onAction().subscribe();
  }

  ShowError(message: string) {
    let config = new MatSnackBarConfig();

    config.panelClass = 'snack-bar-error';

    this.openSnackBar(message, "Close", config).onAction().subscribe();
  }

  openSnackBar(message: string, button: string, config: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {

    return this.snackBar.open(message, button, config);
  }
}
