import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './home/welcome.component';
import { MaterialModule } from './shared/material.module';
import { HeaderComponent } from './base-page/header/header.component';
import { FooterComponent } from './base-page/footer/footer.component';
import { SideBarComponent } from './base-page/side-bar/side-bar.component';
import { MainContentComponent } from './base-page/main-content/main-content.component';
import { FilterComponent } from './base-page/filter/filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VariantDetailsComponent } from './base-page/variant-details/variant-details.component';
import { SubmissionsComponent } from './popups/submissions/submissions.component';
import { AcknowledgementsComponent } from './popups/acknowledgements/acknowledgements.component';
import { ContactComponent } from './popups/contact/contact.component';
import { AppRoutingModule } from './app.routes';
import { AuthService } from './services/auth.service';
import { VariantService } from './services/variant.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SelectReferenceComponent } from './popups/select-reference/select-reference.component';
import { StringFilterByPipe } from './shared/string.filter';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReferencesComponent } from './references/references.component';
import { ReferenceDetailComponent } from './references/reference-detail/reference-detail.component';
import { ConfirmationDialogComponent } from './popups/confirmation-dialog/confirmation-dialog.component';
import { UnpublishedReferenceComponent } from './popups/unpublished-reference/unpublished-reference.component';
import { DictionariesComponent } from './dictionaries/dictionaries.component';
import { DictionaryDetailComponent } from './dictionaries/dictionary-detail/dictionary-detail.component';
import { DictionaryPublishComponent } from './dictionaries/dictionary-publish/dictionary-publish.component';
import { MsalModule, MsalConfig } from '@azure/msal-angular';
import { ErrorInterceptor } from './shared/error.interceptor';
import { AuthInterceptor } from './shared/auth.interceptor';
import { AlertsService } from './services/alerts.service';
import { NewsComponent } from './popups/news/news.component';
import { AppConfigService } from './services/app-config.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';
import { IdleDialogComponent } from './popups/idle-dialog/idle-dialog.component';
import { BulkLoadComponent } from './bulk-load/bulk-load.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerDialogComponent } from './popups/spinner-dialog/spinner-dialog.component';
import { DataDownloadComponent } from './data-download/data-download.component';
import { ReferenceUploadComponent } from './bulk-load/reference-upload/reference-upload.component';
import { VariantUploadComponent } from './bulk-load/variant-upload/variant-upload.component';
import { SelectVariantComponent } from './popups/select-variant/select-variant.component';
import { ReferenceVariantsUploadComponent } from './bulk-load/reference-variants-upload/reference-variants-upload.component';

export function initializeApp(appConfig: AppConfigService) {
  return () => appConfig.loadAppConfig();
}
export function getConfig() {
  var request = new XMLHttpRequest();
  request.open('GET', "/conf.json", false);  // request application settings synchronous
  request.send(null);
  const response = JSON.parse(request.responseText);
  return response as MsalConfig;
}
@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    HeaderComponent,
    FooterComponent,
    SideBarComponent,
    MainContentComponent,
    FilterComponent,
    VariantDetailsComponent,
    SubmissionsComponent,
    AcknowledgementsComponent,
    ContactComponent,
    SelectReferenceComponent,
    StringFilterByPipe,
    ReferencesComponent,
    ReferenceDetailComponent,
    ConfirmationDialogComponent,
    UnpublishedReferenceComponent,
    DictionariesComponent,
    DictionaryDetailComponent,
    DictionaryPublishComponent,
    NewsComponent,
    IdleDialogComponent,
    BulkLoadComponent,
    SpinnerDialogComponent,
    DataDownloadComponent,
    ReferenceUploadComponent,
    VariantUploadComponent,
    SelectVariantComponent,
    ReferenceVariantsUploadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MsalModule.forRoot(getConfig()),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    NgbModule
  ],
  providers: [    
    AuthService,
    AlertsService,
    VariantService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true
    }
      
  ],
  bootstrap: [AppComponent], 
  entryComponents: [
    VariantDetailsComponent,
    SubmissionsComponent,
    AcknowledgementsComponent,
    ContactComponent,
    SelectReferenceComponent,
    ConfirmationDialogComponent,
    UnpublishedReferenceComponent,
    DictionaryPublishComponent,
    NewsComponent,
    IdleDialogComponent,
    SpinnerDialogComponent
  ]
})
export class AppModule { }
