import { Component, OnInit } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-idle-dialog',
  templateUrl: './idle-dialog.component.html',
  styleUrls: ['./idle-dialog.component.scss']
})
export class IdleDialogComponent implements OnInit {
  title: string = "You have been idle for 15 minutes!";
  message: string = "For your security, you will be logged out automatically in ";
  idleState: string;

  constructor(public idle: Idle, private dialogRef: MatDialogRef<IdleDialogComponent>) {

    idle.onIdleEnd.subscribe(() => {
      this.dialogRef.close();
      console.log('Idle ended');
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = countdown
    });
  }

  ngOnInit(): void {
  }
}
