export class Unpublished {

  ReferenceID: number;
  ColumnOrder: number;
  ColumnName: string;
  ColumnValue: string;

  constructor(Order: number) {
    this.ColumnOrder = Order;
  }
}
