import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Dictionary } from '../../models/dictionary';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { VariantService } from '../../services/variant.service';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { DictionaryPublish } from '../../models/dictionaryPublish';
import { ConfirmationDialogComponent } from '../../popups/confirmation-dialog/confirmation-dialog.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'adpkd-dictionary-detail',
  templateUrl: './dictionary-detail.component.html',
  styleUrls: ['./dictionary-detail.component.scss']
})
export class DictionaryDetailComponent implements OnInit {
  publishColumns = ['PublishDate'];
  dictionaryColumns = ['Selected', 'Version', 'Description', 'VariantCount'];
  dictionary: Dictionary;
  publishSource: MatTableDataSource<DictionaryPublish> = new MatTableDataSource;
  dictionarySource: MatTableDataSource<Dictionary> = new MatTableDataSource;
  errorMessage: string;
  emptyDictionary: boolean;
  id: number;
  mode: string;
  ModeText: string = "";
  selectedDictionary: Dictionary;
  versionCategory: string = 'Minor Version';
  categories: string[] = ['Minor Version', 'Major Version'];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialogRef: MatDialogRef<DictionaryDetailComponent>,
              private service: VariantService,
              @Inject(MAT_DIALOG_DATA) data,
              private dialog: MatDialog,
              private authService: AuthService) {

    this.service.Dictionaries().subscribe(data => {
      this.selectedDictionary = data.find(dictionary => dictionary.IsActive);
    });

    this.mode = data.mode;

    if (this.mode == 'edit') {
      this.id = data.id;
      this.ModeText = "Update";
    }

    if (this.mode == 'add') {
      this.ModeText = "Add";
    }
  }

  ngOnInit(): void {

    if (this.id) {
      this.service.getDictionaryByID(this.id).subscribe({
        next: dictionary => {
          this.dictionary = dictionary;

          if (this.dictionary.Publishes != null) {
            this.publishSource.data = this.dictionary.Publishes;

            this.publishSource.sort = this.sort;
          }
        },
        error: err => this.errorMessage = err
      });
    }
    else {
      this.dictionary = new Dictionary();
      this.dictionary.DictionaryID = -1;
      this.dictionary.Publishes = new Array<DictionaryPublish>();
    }

    if (this.mode == 'add') {
      this.service.Dictionaries().subscribe({
        next: dictionaries => {

          this.dictionarySource.data = dictionaries
        },
        error: err => this.errorMessage = err
      });
    }
  }

  saveDictionary(): void {

    if (this.mode == 'edit') {
      this.service.updateDictionary(this.dictionary).subscribe(() => {

        this.service.Dictionaries().subscribe(data => {
          let workingDictionary = data.find(dictionary => dictionary.DictionaryID == this.id);

          workingDictionary.Description = this.dictionary.Description;
        });  
        this.dialogRef.close(this.dictionary);
      });
    }

    if (this.mode == 'add') {
      if (this.versionCategory == 'Minor Version' && !this.emptyDictionary) {
        this.service.getNextMinorDictionaryVersionByID(this.selectedDictionary.DictionaryID).subscribe({
          next: version => {

            this.dictionary.Version = version.Version
            this.addDictionary();
          },
          error: err => this.errorMessage = err
        });
      }
      else {
        this.service.getNextMajorDictionaryVersion().subscribe({
          next: version => {

            this.dictionary.Version = version.Version
            this.addDictionary();
          },
          error: err => this.errorMessage = err
        });
      }
    }
  }

  private addDictionary() {

    if (this.emptyDictionary) {
      this.service.addDictionary(this.dictionary).subscribe(() => {
        this.service.loadDictionaries();
        this.dialogRef.close(this.dictionary);
      });
    }
    else {
      this.service.addDictionary(this.dictionary, this.selectedDictionary.DictionaryID).subscribe(() => {
        this.service.loadDictionaries();
        this.dialogRef.close(this.dictionary);
      });
    }
  }

  deleteDictionary(): void {

    if (this.mode == 'edit') {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent,
        { data: { title: 'Delete Dictionary', message: 'Are you sure you wish to delete this dictionary?' }, disableClose: true, maxWidth: '95%', maxHeight: '95%' });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.service.deleteDictionary(this.dictionary).subscribe(() => {
            this.service.loadDictionaries();
            this.service.changeToActiveDictionary();
            this.dialogRef.close("Dictionary Deleted");
          });
        }
      });
    }
  }

  selectDictionary(dictionary: Dictionary) {
    this.selectedDictionary = dictionary;
  }
}
