import { Unpublished } from './unpublished';

export class Reference {
  ReferenceID: number;
  Title: string;
  Description: string;
  URL: string;
  Unpublished: boolean;
  UnpublishedReference: Unpublished[];

  //constructor(id: number, title: string, description: string) {
  //  this.ReferenceID = id;
  //  this.Title = title;
  //  this.Description = description;
  //}
}

