export class Filter {
  gene: number;
  classification: number;
  variantType: number;
  clinicalSignificance: number;
  researchSignificance: number;
  predictedPenetrance: number;
  region: number;
  searchPhrase: string;
  searchCodon: string;
  status: string;
  reference: number;

  constructor(gene: number, classification: number, variantType: number, clinicalSignificance: number, researchSignificance: number, predictedPenetrance: number, region: number, searchPhrase: string, searchCodon: string, status: string, reference: number) {
    this.gene = gene;
    this.classification = classification;
    this.variantType = variantType;
    this.clinicalSignificance = clinicalSignificance;
    this.researchSignificance = researchSignificance;
    this.region = region;
    this.predictedPenetrance = predictedPenetrance;
    this.searchPhrase = searchPhrase;
    this.searchCodon = searchCodon;
    this.status = status;
    this.reference = reference;
  }
}
