import { Component, OnInit, Input } from '@angular/core';
import { BulkUpload } from '../../models/bulkUpload';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VariantUpload } from '../../models/variantUpload';
import { Gene } from '../../models/gene';
import { Classification } from '../../models/classification';
import { VariantType } from '../../models/variantType';
import { ClinicalSignificance } from '../../models/clinicalSignificance';
import { PredictedPenetrance } from '../../models/predictedPenetrance';
import { Region } from '../../models/region';
import { PopulationData } from '../../models/populationData';
import { PredictiveData } from '../../models/predictiveData';
import { FunctionalData } from '../../models/functionalData';
import { SegregationData } from '../../models/segregationData';
import { DeNovoData } from '../../models/deNovoData';
import { AlleleData } from '../../models/alleleData';
import { OtherDatabase } from '../../models/otherDatabase';
import { OtherData } from '../../models/otherData';
import { Reference } from '../../models/reference';
import { MatTableDataSource } from '@angular/material/table';
import { VariantService } from '../../services/variant.service';
import { AlertsService } from '../../services/alerts.service';
import { SelectReferenceComponent } from '../../popups/select-reference/select-reference.component';
import { BulkUploadVariants } from '../../models/bulkUploadVariants';
import { SpinnerDialogComponent } from '../../popups/spinner-dialog/spinner-dialog.component';

@Component({
  selector: 'adpkd-variant-upload',
  templateUrl: './variant-upload.component.html',
  styleUrls: ['./variant-upload.component.scss']
})
export class VariantUploadComponent implements OnInit {
  componentType = "Variant"

  columns = ['Remove', 'Warning', 'Error', 'Saved', 'Gene', 'cDNADesignation', 'AminoAcidDesignation', 'Classification', 'VariantType', 'ClinicalSignificance',
    'PredictedPenetrance', 'GenomicPositionHG37', 'GenomicPositionHG38', 'Codon', 'ReferenceSequencePosition', 'ReferenceSequencePositionStop',
    'RegionDisplay', 'RegionStart', 'RegionEnd', 'gnomADID', 'gnomADAlleleCount', 'gnomADAlleleNumber', 'Comment', 'PopulationData',
    'PredictiveData', 'FunctionalData', 'SegregationData', 'DeNovoData', 'AlleleData', 'OtherDatabase', 'OtherData', 'SubstitutionScore',
    'ContextualScore', 'VariantScore', 'OrthologGVGD', 'DomainGVGD', 'gnomADScore', 'StructureFunctionScore', 'OtherChangeAtResidue',
    'SplicingScore', 'OtherDescriptionOfVariant', 'OtherVariantInPatient', 'Segregation', 'ReferenceID', 'Reference', 'ReferenceURL', 'ReferenceFamily'];


  errorMessage: string;
  @Input() bulkUpload: BulkUpload;
  bulkUploadVariants: BulkUploadVariants;

  filteredVariants: VariantUpload[];

  genes: Gene[];
  classifications: Classification[];
  variantTypes: VariantType[];
  clinicalSignificances: ClinicalSignificance[];
  predictedPenetrances: PredictedPenetrance[];
  regions: Region[];
  populations: PopulationData[];
  predictives: PredictiveData[];
  functionals: FunctionalData[];
  segregations: SegregationData[];
  deNovos: DeNovoData[];
  alleles: AlleleData[];
  otherDatabases: OtherDatabase[];
  others: OtherData[];
  references: Reference[];

  isChecked: boolean = false;
  isWarn: boolean = false;
  isError: boolean = false;
  isSave: boolean = false;
  isChanged: boolean = true;

  dialogRef: MatDialogRef<SpinnerDialogComponent>;

  source: MatTableDataSource<VariantUpload> = new MatTableDataSource;

  constructor(private service: VariantService, private alerts: AlertsService, private dialog: MatDialog) {  
    
  }

  ngOnInit(): void {
    this.bulkUploadVariants = new BulkUploadVariants();
    this.bulkUploadVariants.Variants = new Array<VariantUpload>();

    this.source = new MatTableDataSource<VariantUpload>(this.bulkUploadVariants.Variants);

    this.service.getGenes().subscribe({
      next: genes => {
        this.genes = genes
      },
      error: err => this.errorMessage = err
    });

    this.service.getClassifications().subscribe({
      next: classifications => {
        this.classifications = classifications
      },
      error: err => this.errorMessage = err
    });

    this.service.getVariantTypes().subscribe({
      next: variantTypes => {
        this.variantTypes = variantTypes
      },
      error: err => this.errorMessage = err
    });

    this.service.getClinicalSignificances().subscribe({
      next: clinicalSignificances => {
        this.clinicalSignificances = clinicalSignificances
      },
      error: err => this.errorMessage = err
    });

    this.service.getPredictedPenetrances().subscribe({
      next: predictedPenetrances => {
        this.predictedPenetrances = predictedPenetrances
      },
      error: err => this.errorMessage = err
    });

    this.service.getRegions().subscribe({
      next: regions => {
        this.regions = regions
      },
      error: err => this.errorMessage = err
    });

    this.service.getPopulationData().subscribe({
      next: populations => {
        this.populations = populations
      },
      error: err => this.errorMessage = err
    });

    this.service.getPredictiveData().subscribe({
      next: predictives => {
        this.predictives = predictives
      },
      error: err => this.errorMessage = err
    });

    this.service.getFunctionalData().subscribe({
      next: functionals => {
        this.functionals = functionals
      },
      error: err => this.errorMessage = err
    });

    this.service.getSegregationData().subscribe({
      next: segregations => {
        this.segregations = segregations
      },
      error: err => this.errorMessage = err
    });

    this.service.getDeNovoData().subscribe({
      next: deNovos => {
        this.deNovos = deNovos
      },
      error: err => this.errorMessage = err
    });

    this.service.getAlleleData().subscribe({
      next: alleles => {
        this.alleles = alleles
      },
      error: err => this.errorMessage = err
    });

    this.service.getOtherDatabase().subscribe({
      next: otherDatabases => {
        this.otherDatabases = otherDatabases
      },
      error: err => this.errorMessage = err
    });

    this.service.getOtherData().subscribe({
      next: others => {
        this.others = others
      },
      error: err => this.errorMessage = err
    });

    this.service.getReferences().subscribe({
      next: references => {
        this.references = references
      },
      error: err => this.errorMessage = err
    });
  }

  ngOnChanges() {

    this.ngOnInit();
    this.isChecked = false;
    this.isWarn = false;
    this.isError = false;
    this.isSave = false;
    this.isChanged = true;
  }

  add() {
    let variant = new VariantUpload;

    variant.Warnings = new Array<string>();
    variant.Errors = new Array<string>();
    variant.Errors.push("Gene is missing!");
    variant.Errors.push("cDNA Designation is missing!");
    variant.Errors.push("Amino Acid Designation is missing!");
    variant.Errors.push("Classification is missing!");
    variant.Errors.push("Variant Type is missing!");
    variant.Errors.push("Clinical Significance is missing!");

    variant.IsVerified = false;

    this.bulkUploadVariants.Variants.push(variant);

    this.filter();

    this.isChanged = true;
  }

  remove() {
    this.bulkUploadVariants.Variants = this.bulkUploadVariants.Variants.filter(variant => !variant.Delete)

    this.isChecked = false;
    this.isWarn = false;
    this.isError = false;
    this.isSave = false;

    this.filter();

    this.isChanged = true;
  }

  reset() {
    this.isChecked = false;
    this.isWarn = false;
    this.isError = false;
    this.isSave = false;
    this.isChanged = true;

    this.bulkUploadVariants.Variants.length = 0;
  }

  load(bulkUploadVariants: BulkUploadVariants) {

    this.bulkUploadVariants = bulkUploadVariants;
    this.filter();
    this.isChanged = true;
  }

  filter() {

    this.filteredVariants = this.bulkUploadVariants.Variants;

    if (this.isWarn == true) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.Warnings.length > 0);
    }

    if (this.isError == true) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.Errors.length > 0);
    }

    if (this.isSave == true) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.IsUploaded);
    }

    if (this.filteredVariants != null) { this.source.data = this.filteredVariants; }
  }

  selectAll(checked: boolean) {
    this.filteredVariants.forEach(variant => variant.Delete = checked);
    if (this.filteredVariants != null) { this.source.data = this.filteredVariants; }
  }

  showMessage(messages: string[]) {
    let message: string = "<table>";

    messages.forEach(value => {

      message += "<tr><td>" + value + "</td></tr>";
    });

    message += "</table>";

    return message;
  }

  showPopover(popover, object: string[]) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ object });
    }
  }

  selectionChanged(variant: VariantUpload, field: string, value: any) {

    if (variant.Errors.some(err => err.indexOf('Variant Already Exists in Working Dictionary!') > -1)) {
      const index = variant.Errors.indexOf('Variant Already Exists in Working Dictionary!', 0);

      if (index > -1) {
        variant.Errors.splice(index, 1);
      }
    }

    if (value == null || value == '') {
      this.addErrorField(variant, field);
    }
    else {
      this.removeErrorField(variant, field);
    }

    variant.IsVerified = false;
    this.isChanged = true;
  }

  referenceChanged(variant: VariantUpload) {

    if ((variant.Reference == null || variant.Reference == '') &&
      ((variant.ReferenceURL != null && variant.ReferenceURL != '') ||
      (variant.ReferenceFamily != null && variant.ReferenceFamily != ''))) {
      if (!this.errorFieldExists(variant, "Reference Name")) {
        this.addErrorField(variant, "Reference Name");
      }
    }
    else {
      this.removeErrorField(variant, "Reference Name");
    }

    this.isChanged = true;
  }

  openSelectReference(variant: VariantUpload, mode: string) {
    let dialogRef = this.dialog.open(SelectReferenceComponent, { data: { mode: mode, id: variant.ReferenceID }, disableClose: false, maxWidth: '95%', maxHeight: '95%' }
    );

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let ref: Reference = result as Reference;

        variant.ReferenceID = ref.ReferenceID;
        variant.Reference = ref.Title;
        variant.ReferenceURL = ref.URL;

        this.isChanged = true;

        this.removeErrorField(variant, "Reference Name");
      }
    });
  }

  clearReference(variant: VariantUpload) {

    variant.ReferenceID = null
    variant.Reference = null;
    variant.ReferenceURL = null;
    variant.ReferenceFamily = null;

    this.isChanged = true;
  }

  errorFieldExists(variant: VariantUpload, field: string) {
    return variant.Errors.some(err => err.indexOf(field + ' is missing!') > -1);
  }

  addErrorField(variant: VariantUpload, field: string) {

    if (!this.errorFieldExists(variant, field)) {
      variant.Errors.push(field + " is missing!");
    }
  }

  removeErrorField(variant: VariantUpload, field: string) {

    const index = variant.Errors.indexOf(field + " is missing!", 0);

    if (index > -1) {
      variant.Errors.splice(index, 1);
    }
  }

  haveWarnings() {

    if (this.filteredVariants == null) {
      return false;
    }

    return this.filteredVariants.some(variant => variant.Warnings.length > 0);
  }

  haveErrors() {

    if (this.filteredVariants == null) {
      return false;
    }

    return this.filteredVariants.some(variant => variant.Errors.length > 0);
  }

  haveSaves() {

    if (this.filteredVariants == null) {
      return false;
    }

    return this.filteredVariants.some(variant => variant.IsUploaded == true);
  }

  anyChecked() {

    if (this.filteredVariants == null) {
      return false;
    }

    return this.filteredVariants.some(variant => variant.Delete == true);
  }

  canVerify() {
    return !this.bulkUploadVariants.Variants.some(variant => variant.Errors.length > 0) && this.bulkUploadVariants.Variants.length > 0 && !this.bulkUploadVariants.IsComplete && this.isChanged;
  }

  verify() {

    this.dialogRef = this.dialog.open(SpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });

    this.bulkUploadVariants.Description = this.bulkUpload.Description;
    this.bulkUploadVariants.UploadTypeID = this.bulkUpload.UploadTypeID;

    this.service.verifyBulkUploadVariants(this.bulkUploadVariants).subscribe({
      next: value => {

        this.bulkUploadVariants = value;
        this.filter();

        this.isChanged = false;

        this.dialogRef.close();
      },
      error: err => {
        this.errorMessage = err;
        this.alerts.ShowError(err);
        this.dialogRef.close();
      }
    });
  }

  canUpload() {

    return !this.bulkUploadVariants.Variants.some(variant => variant.IsVerified == false || variant.IsVerified == null) &&
                          this.bulkUploadVariants.Variants.length > 0 && !this.bulkUploadVariants.IsComplete && !this.isChanged;
  }

  upload() {

    if (!this.bulkUploadVariants.IsComplete) {

      this.dialogRef = this.dialog.open(SpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true
      });

      this.bulkUploadVariants.Description = this.bulkUpload.Description;
      this.bulkUploadVariants.UploadTypeID = this.bulkUpload.UploadTypeID;

      this.service.uploadBulkUploadVariants(this.bulkUploadVariants).subscribe({
        next: value => {

          this.bulkUploadVariants = value;
          this.bulkUpload.IsComplete = this.bulkUploadVariants.IsComplete;
          this.filter();
          this.dialogRef.close();
        },
        error: err => {
          this.errorMessage = err;
          this.alerts.ShowError(err);
          this.dialogRef.close();
        }
      });
    }
  }

}
