import { VariantReference } from './variantReference';

export class Variant {
  VariantID: number;
  InternalOnly: number;
  Version: number;
  GeneID: number;
  GeneName: string;
  ClassificationID: number;
  ClassificationName: string;
  VariantTypeID: number;
  VariantTypeName: string;
  ClinicalSignificanceID: number;
  ClinicalSignificanceName: string;
  ClinicalSignificanceShortName: string;
  ResearchSignificanceID: number;
  ResearchSignificanceName: string;
  ResearchSignificanceShortName: string;
  PredictedPenetranceID: number;
  PredictedPenetranceName: string;
  RecordFlagID: number;
  RecordFlagName: string;
  cDNADesignation: string;
  AminoAcidDesignation: string;
  GenomicPositionHG37: string;
  GenomicPositionHG38: string;
  Codon: number;
  ReferenceSequencePosition: number;
  ReferenceSequencePositionStop: number;
  RegionDisplay: string;
  RegionStartID: number;
  RegionStartName: string;
  RegionEndID: number;
  RegionEndName: string;
  gnomADID: string;
  gnomADAlleleCount: number;
  gnomADAlleleNumber: number;
  Comment: string;
  Verified: boolean;
  PopulationDataID: number;
  PopulationDataName: string;
  PredictiveDataID: number;
  PredictiveDataName: string;
  FunctionalDataID: number;
  FunctionalDataName: string;
  SegregationDataID: number;
  SegregationDataName: string;
  DeNovoDataID: number;
  DeNovoDataName: string;
  AlleleDataID: number;
  AlleleDataName: string;
  OtherDatabaseID: number;
  OtherDatabaseName: string;
  OtherDataID: number;
  OtherDataName: string;
  SubstitutionScore: number;
  ContextualScore: number;
  VariantScore: number;
  OrthologGVGD: number;
  DomainGVGD: number;
  gnomADScore: number;
  StructureFunctionScore: number;
  OtherChangeAtResidue: number;
  SplicingScore: number;
  OtherDescriptionOfVariant: number;
  OtherVariantInPatient: number;
  Segregation: number;
  Validated: boolean;
  VariantReferences: VariantReference[];
  PedigreeCount: number;
  PaperCount: number;
  Updated: number;
  ReferenceList: number[];
  RefSeqLink: string;
  HG37Link: string;
  HG38Link: string;
}
