import { Component, OnInit, Input } from '@angular/core';
import { BulkUpload } from '../../models/bulkUpload';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VariantUpload } from '../../models/variantUpload';
import { Gene } from '../../models/gene';
import { Classification } from '../../models/classification';
import { VariantType } from '../../models/variantType';
import { ClinicalSignificance } from '../../models/clinicalSignificance';
import { PredictedPenetrance } from '../../models/predictedPenetrance';
import { Region } from '../../models/region';
import { PopulationData } from '../../models/populationData';
import { PredictiveData } from '../../models/predictiveData';
import { FunctionalData } from '../../models/functionalData';
import { SegregationData } from '../../models/segregationData';
import { DeNovoData } from '../../models/deNovoData';
import { AlleleData } from '../../models/alleleData';
import { OtherDatabase } from '../../models/otherDatabase';
import { OtherData } from '../../models/otherData';
import { Reference } from '../../models/reference';
import { MatTableDataSource } from '@angular/material/table';
import { VariantService } from '../../services/variant.service';
import { AlertsService } from '../../services/alerts.service';
import { SelectReferenceComponent } from '../../popups/select-reference/select-reference.component';
import { ReferenceUpload } from '../../models/referenceUpload';
import { SelectVariantComponent } from '../../popups/select-variant/select-variant.component';
import { BulkUploadReferenceVariants } from '../../models/bulkUploadReferenceVariants';
import { SpinnerDialogComponent } from '../../popups/spinner-dialog/spinner-dialog.component';

@Component({
  selector: 'adpkd-reference-variants-upload',
  templateUrl: './reference-variants-upload.component.html',
  styleUrls: ['./reference-variants-upload.component.scss']
})
export class ReferenceVariantsUploadComponent implements OnInit {
  componentType = "Reference Variant"

  columns = ['Remove', 'Warning', 'Error', 'Saved', 'ReferenceFamily', 'VariantID', 'Gene', 'cDNADesignation', 'AminoAcidDesignation', 'Classification', 'VariantType', 'ClinicalSignificance',
    'PredictedPenetrance', 'GenomicPositionHG37', 'GenomicPositionHG38', 'Codon', 'ReferenceSequencePosition', 'ReferenceSequencePositionStop',
    'RegionDisplay', 'RegionStart', 'RegionEnd', 'gnomADID', 'gnomADAlleleCount', 'gnomADAlleleNumber', 'Comment', 'PopulationData',
    'PredictiveData', 'FunctionalData', 'SegregationData', 'DeNovoData', 'AlleleData', 'OtherDatabase', 'OtherData', 'SubstitutionScore',
    'ContextualScore', 'VariantScore', 'OrthologGVGD', 'DomainGVGD', 'gnomADScore', 'StructureFunctionScore', 'OtherChangeAtResidue',
    'SplicingScore', 'OtherDescriptionOfVariant', 'OtherVariantInPatient', 'Segregation'];


  errorMessage: string;
  @Input() bulkUpload: BulkUpload;
  bulkUploadReferenceVariants: BulkUploadReferenceVariants;

  filteredVariants: VariantUpload[];

  genes: Gene[];
  classifications: Classification[];
  variantTypes: VariantType[];
  clinicalSignificances: ClinicalSignificance[];
  predictedPenetrances: PredictedPenetrance[];
  regions: Region[];
  populations: PopulationData[];
  predictives: PredictiveData[];
  functionals: FunctionalData[];
  segregations: SegregationData[];
  deNovos: DeNovoData[];
  alleles: AlleleData[];
  otherDatabases: OtherDatabase[];
  others: OtherData[];
  references: Reference[];

  isChecked: boolean = false;
  isWarn: boolean = false;
  isError: boolean = false;
  isSave: boolean = false;
  isChanged: boolean = true;

  dialogRef: MatDialogRef<SpinnerDialogComponent>;

  source: MatTableDataSource<VariantUpload> = new MatTableDataSource;

  constructor(private service: VariantService, private alerts: AlertsService, private dialog: MatDialog) {

  }

  ngOnInit(): void {

    this.bulkUploadReferenceVariants = new BulkUploadReferenceVariants();
    this.bulkUploadReferenceVariants.Reference = new ReferenceUpload();
    this.bulkUploadReferenceVariants.Variants = new Array<VariantUpload>();

    this.source = new MatTableDataSource<VariantUpload>(this.bulkUploadReferenceVariants.Variants);

    this.service.getGenes().subscribe({
      next: genes => {
        this.genes = genes
      },
      error: err => this.errorMessage = err
    });

    this.service.getClassifications().subscribe({
      next: classifications => {
        this.classifications = classifications
      },
      error: err => this.errorMessage = err
    });

    this.service.getVariantTypes().subscribe({
      next: variantTypes => {
        this.variantTypes = variantTypes
      },
      error: err => this.errorMessage = err
    });

    this.service.getClinicalSignificances().subscribe({
      next: clinicalSignificances => {
        this.clinicalSignificances = clinicalSignificances
      },
      error: err => this.errorMessage = err
    });

    this.service.getPredictedPenetrances().subscribe({
      next: predictedPenetrances => {
        this.predictedPenetrances = predictedPenetrances
      },
      error: err => this.errorMessage = err
    });

    this.service.getRegions().subscribe({
      next: regions => {
        this.regions = regions
      },
      error: err => this.errorMessage = err
    });

    this.service.getPopulationData().subscribe({
      next: populations => {
        this.populations = populations
      },
      error: err => this.errorMessage = err
    });

    this.service.getPredictiveData().subscribe({
      next: predictives => {
        this.predictives = predictives
      },
      error: err => this.errorMessage = err
    });

    this.service.getFunctionalData().subscribe({
      next: functionals => {
        this.functionals = functionals
      },
      error: err => this.errorMessage = err
    });

    this.service.getSegregationData().subscribe({
      next: segregations => {
        this.segregations = segregations
      },
      error: err => this.errorMessage = err
    });

    this.service.getDeNovoData().subscribe({
      next: deNovos => {
        this.deNovos = deNovos
      },
      error: err => this.errorMessage = err
    });

    this.service.getAlleleData().subscribe({
      next: alleles => {
        this.alleles = alleles
      },
      error: err => this.errorMessage = err
    });

    this.service.getOtherDatabase().subscribe({
      next: otherDatabases => {
        this.otherDatabases = otherDatabases
      },
      error: err => this.errorMessage = err
    });

    this.service.getOtherData().subscribe({
      next: others => {
        this.others = others
      },
      error: err => this.errorMessage = err
    });

    this.service.getReferences().subscribe({
      next: references => {
        this.references = references
      },
      error: err => this.errorMessage = err
    });
  }

  ngOnChanges() {

    this.ngOnInit();
    this.isChecked = false;
    this.isWarn = false;
    this.isError = false;
    this.isSave = false;
    this.isChanged = true;
  }

  add() {
    let variant = new VariantUpload;

    variant.Warnings = new Array<string>();
    variant.Errors = new Array<string>();
    variant.Errors.push("Gene is missing!");
    variant.Errors.push("cDNA Designation is missing!");
    variant.Errors.push("Amino Acid Designation is missing!");
    variant.Errors.push("Classification is missing!");
    variant.Errors.push("Variant Type is missing!");
    variant.Errors.push("Clinical Significance is missing!");

    variant.IsVerified = false;

    this.bulkUploadReferenceVariants.Variants.push(variant);

    this.filter();

    this.isChanged = true;
  }

  remove() {
    this.bulkUploadReferenceVariants.Variants = this.bulkUploadReferenceVariants.Variants.filter(variant => !variant.Delete)

    this.isChecked = false;
    this.isWarn = false;
    this.isError = false;
    this.isSave = false;

    this.filter();

    this.isChanged = true;
  }

  reset() {
    this.isChecked = false;
    this.isWarn = false;
    this.isError = false;
    this.isSave = false;
    this.isChanged = true;

    this.bulkUploadReferenceVariants.Variants.length = 0;
  }

  load(bulkUploadReferenceVariants: BulkUploadReferenceVariants) {

    this.bulkUploadReferenceVariants = bulkUploadReferenceVariants;
    this.filter();
    this.isChanged = true;
  }

  filter() {

    this.filteredVariants = this.bulkUploadReferenceVariants.Variants;

    if (this.isWarn == true) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.Warnings.length > 0);
    }

    if (this.isError == true) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.Errors.length > 0);
    }

    if (this.isSave == true) {
      this.filteredVariants = this.filteredVariants.filter(variant => variant.IsUploaded);
    }

    if (this.filteredVariants != null) { this.source.data = this.filteredVariants; }
  }

  selectAll(checked: boolean) {
    this.filteredVariants.forEach(variant => variant.Delete = checked);
    if (this.filteredVariants != null) { this.source.data = this.filteredVariants; }
  }

  showMessage(messages: string[]) {
    let message: string = "<table>";

    messages.forEach(value => {

      message += "<tr><td>" + value + "</td></tr>";
    });

    message += "</table>";

    return message;
  }

  showPopover(popover, object: string[]) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ object });
    }
  }

  selectionChanged(variant: VariantUpload, field: string, value: any) {

    if (value == null || value == '') {
      this.addErrorField(variant, field);
    }
    else {
      this.removeErrorField(variant, field);
    }

    variant.IsVerified = false;
    this.isChanged = true;
  }

  openSelectReference(reference: Reference, mode: string) {
    let dialogRef = this.dialog.open(SelectReferenceComponent, { data: { mode: mode, id: reference.ReferenceID }, disableClose: false, maxWidth: '95%', maxHeight: '95%' }
    );

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let ref: Reference = result as Reference;

        reference.ReferenceID = ref.ReferenceID;
        reference.Title = ref.Title;
        reference.URL = ref.URL;

        this.isChanged = true;
      }
    });
  }

  clearReference(reference: Reference) {

    reference.ReferenceID = null
    reference.Title = null;
    reference.URL = null;

    this.isChanged = true;
  }

  openSelectVariant(variant: VariantUpload, mode: string) {
    let dialogRef = this.dialog.open(SelectVariantComponent, { data: { mode: mode, id: variant.VariantID }, disableClose: false, maxWidth: '95%', maxHeight: '95%' }
    );

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let obj: VariantUpload = result as VariantUpload;

        variant.VariantID = obj.VariantID;
        variant.GeneID = obj.GeneID;
        variant.cDNADesignation = obj.cDNADesignation;
        variant.AminoAcidDesignation = obj.AminoAcidDesignation;
        variant.ClassificationID = obj.ClassificationID;
        variant.VariantTypeID = obj.VariantTypeID;
        variant.ClinicalSignificanceID = obj.ClinicalSignificanceID;
        variant.PredictedPenetranceID = obj.PredictedPenetranceID;
        variant.GenomicPositionHG37 = obj.GenomicPositionHG37;
        variant.GenomicPositionHG38 = obj.GenomicPositionHG38;
        variant.Codon = obj.Codon;
        variant.ReferenceSequencePosition = obj.ReferenceSequencePosition;
        variant.ReferenceSequencePositionStop = obj.ReferenceSequencePositionStop;
        variant.RegionDisplay = obj.RegionDisplay;
        variant.RegionStartID = obj.RegionStartID;
        variant.RegionEndID = obj.RegionEndID;
        variant.gnomADID = obj.gnomADID;
        variant.gnomADAlleleCount = obj.gnomADAlleleCount;
        variant.gnomADAlleleNumber = obj.gnomADAlleleNumber;
        variant.Comment = obj.Comment;
        variant.PopulationDataID = obj.PopulationDataID;
        variant.PredictiveDataID = obj.PredictiveDataID;
        variant.FunctionalDataID = obj.FunctionalDataID;
        variant.SegregationDataID = obj.SegregationDataID;
        variant.DeNovoDataID = obj.DeNovoDataID;
        variant.AlleleDataID = obj.AlleleDataID;
        variant.OtherDatabaseID = obj.OtherDatabaseID;
        variant.OtherDataID = obj.OtherDataID;
        variant.SubstitutionScore = obj.SubstitutionScore;
        variant.ContextualScore = obj.ContextualScore;
        variant.VariantScore = obj.VariantScore;
        variant.OrthologGVGD = obj.OrthologGVGD;
        variant.DomainGVGD = obj.DomainGVGD;
        variant.gnomADScore = obj.gnomADScore;
        variant.StructureFunctionScore = obj.StructureFunctionScore;
        variant.OtherChangeAtResidue = obj.OtherChangeAtResidue;
        variant.SplicingScore = obj.SplicingScore;
        variant.OtherDescriptionOfVariant = obj.OtherDescriptionOfVariant;
        variant.OtherVariantInPatient = obj.OtherVariantInPatient;
        variant.Segregation = obj.Segregation;

        this.selectionChanged(variant, 'Gene', variant.GeneID);
        this.selectionChanged(variant, 'cDNA Designation', variant.cDNADesignation);
        this.selectionChanged(variant, 'Amino Acid Designation', variant.AminoAcidDesignation);
        this.selectionChanged(variant, 'Classification', variant.ClassificationID);
        this.selectionChanged(variant, 'Variant Type', variant.VariantTypeID);
        this.selectionChanged(variant, 'Clinical Significance', variant.ClinicalSignificanceID);

        this.isChanged = true;
      }
    });
  }

  clearVariant(variant: VariantUpload) {

    variant.VariantID = null
    variant.GeneID = null;
    variant.cDNADesignation = null;
    variant.AminoAcidDesignation = null;
    variant.ClassificationID = null;
    variant.VariantTypeID = null;
    variant.ClinicalSignificanceID = null;
    variant.PredictedPenetranceID = null;
    variant.GenomicPositionHG37 = null;
    variant.GenomicPositionHG38 = null;
    variant.Codon = null;
    variant.ReferenceSequencePosition = null;
    variant.ReferenceSequencePositionStop = null;
    variant.RegionDisplay = null;
    variant.RegionStartID = null;
    variant.RegionEndID = null;
    variant.gnomADID = null;
    variant.gnomADAlleleCount = null;
    variant.gnomADAlleleNumber = null;
    variant.Comment = null;
    variant.PopulationDataID = null;
    variant.PredictiveDataID = null;
    variant.FunctionalDataID = null;
    variant.SegregationDataID = null;
    variant.DeNovoDataID = null;
    variant.AlleleDataID = null;
    variant.OtherDatabaseID = null;
    variant.OtherDataID = null;
    variant.SubstitutionScore = null;
    variant.ContextualScore = null;
    variant.VariantScore = null;
    variant.OrthologGVGD = null;
    variant.DomainGVGD = null;
    variant.gnomADScore = null;
    variant.StructureFunctionScore = null;
    variant.OtherChangeAtResidue = null;
    variant.SplicingScore = null;
    variant.OtherDescriptionOfVariant = null;
    variant.OtherVariantInPatient = null;
    variant.Segregation = null;

    this.selectionChanged(variant, 'Gene', variant.GeneID);
    this.selectionChanged(variant, 'cDNA Designation', variant.cDNADesignation);
    this.selectionChanged(variant, 'Amino Acid Designation', variant.AminoAcidDesignation);
    this.selectionChanged(variant, 'Classification', variant.ClassificationID);
    this.selectionChanged(variant, 'Variant Type', variant.VariantTypeID);
    this.selectionChanged(variant, 'Clinical Significance', variant.ClinicalSignificanceID);

    this.isChanged = true;
  }

  errorFieldExists(variant: VariantUpload, field: string) {
    return variant.Errors.some(err => err.indexOf(field + ' is missing!') > -1);
  }

  addErrorField(variant: VariantUpload, field: string) {

    if (!this.errorFieldExists(variant, field)) {
      variant.Errors.push(field + " is missing!");
    }
  }

  removeErrorField(variant: VariantUpload, field: string) {

    const index = variant.Errors.indexOf(field + " is missing!", 0);

    if (index > -1) {
      variant.Errors.splice(index, 1);
    }
  }

  haveWarnings() {

    if (this.filteredVariants == null) {
      return false;
    }

    return this.filteredVariants.some(variant => variant.Warnings.length > 0);
  }

  haveErrors() {

    if (this.filteredVariants == null) {
      return false;
    }

    return this.filteredVariants.some(variant => variant.Errors.length > 0);
  }

  haveSaves() {

    if (this.filteredVariants == null) {
      return false;
    }

    return this.filteredVariants.some(variant => variant.IsUploaded == true);
  }

  anyChecked() {

    if (this.filteredVariants == null) {
      return false;
    }

    return this.filteredVariants.some(variant => variant.Delete == true);
  }

  canVerify() {
    return !this.bulkUploadReferenceVariants.Variants.some(variant => variant.Errors.length > 0) && this.bulkUploadReferenceVariants.Variants.length > 0 &&
      this.bulkUploadReferenceVariants.Reference.Title?.trim().length > 0 && !this.bulkUploadReferenceVariants.IsComplete && this.isChanged;
  }

  verify() {

    this.dialogRef = this.dialog.open(SpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });

    this.bulkUploadReferenceVariants.Description = this.bulkUpload.Description;
    this.bulkUploadReferenceVariants.UploadTypeID = this.bulkUpload.UploadTypeID;

    this.service.verifyBulkUploadReferenceVariants(this.bulkUploadReferenceVariants).subscribe({
      next: value => {

        this.bulkUploadReferenceVariants = value;
        this.filter();

        this.isChanged = false;

        this.dialogRef.close();
      },
      error: err => {
        this.errorMessage = err;
        this.alerts.ShowError(err);
        this.dialogRef.close();
      }
    });
  }

  canUpload() {

    return !this.bulkUploadReferenceVariants.Variants.some(variant => variant.IsVerified == false || variant.IsVerified == null) &&
            this.bulkUploadReferenceVariants.Variants.length > 0 &&
            this.bulkUploadReferenceVariants.Reference?.IsVerified &&
            !this.bulkUploadReferenceVariants.IsComplete &&
            !this.isChanged;
  }

  upload() {

    if (!this.bulkUploadReferenceVariants.IsComplete) {

      this.dialogRef = this.dialog.open(SpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true
      });

      this.bulkUploadReferenceVariants.Description = this.bulkUpload.Description;
      this.bulkUploadReferenceVariants.UploadTypeID = this.bulkUpload.UploadTypeID;

      this.service.uploadBulkUploadReferenceVariants(this.bulkUploadReferenceVariants).subscribe({
        next: value => {

          this.bulkUploadReferenceVariants = value;
          this.bulkUpload.IsComplete = this.bulkUploadReferenceVariants.IsComplete;
          this.filter();
          this.dialogRef.close();
        },
        error: err => {
          this.errorMessage = err;
          this.alerts.ShowError(err);
          this.dialogRef.close();
        }
      });
    }
  }
}
