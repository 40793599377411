import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './home/welcome.component';
import { MainContentComponent } from './base-page/main-content/main-content.component';
import { ReferencesComponent } from './references/references.component';
import { DictionariesComponent } from './dictionaries/dictionaries.component';
import { AuthGuard } from './guards/auth.guard';
import { Role } from './models/role';
import { BulkLoadComponent } from './bulk-load/bulk-load.component';
import { DataDownloadComponent } from './data-download/data-download.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeComponent
  },
  {
    path: 'variants',
    component: MainContentComponent
  },
  {
    path: 'references',
    component: ReferencesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Publisher, Role.Editor] }
  },
  {
    path: 'dictionaries',
    component: DictionariesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Publisher, Role.Editor] }
  },
  {
    path: 'bulk-load',
    component: BulkLoadComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Publisher, Role.Editor] }
  },
  {
    path: 'data-download',
    component: DataDownloadComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Publisher, Role.Editor]}
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'welcome',
    pathMatch: 'full'
  }
];   

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
